import { Component, OnInit } from '@angular/core';
import {GaleriaService} from '../gallery/service/galeria.service'
import { Injectable } from '@angular/core';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';
import { Imagen, Videos } from './interfaces';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.css'],
  providers: [NgbCarouselConfig]  // add NgbCarouselConfig to the component providers
})

export class GalleryComponent implements OnInit {

  images: Imagen[] = [];
  videos: Videos[] = [];

  contenido: Imagen[] | Videos[] = []

  objActuallity: Imagen | Videos = null

  videoBool = false;
  imagenBool = true;

  p:number =1;
  images3 = [944, 1011, 984].map((n) => `https://picsum.photos/id/${n}/900/500`);
  private url  = environment.apiUrl;

  images2 = [700, 800, 807].map((n) => `https://picsum.photos/id/${n}/900/500`);
  constructor(
    private modalService: NgbModal,
    private service_galeria: GaleriaService,
    config: NgbCarouselConfig
  ) {
    config.interval = 2000;
    config.keyboard = true;
    config.pauseOnHover = true;
  }

  ngOnInit(): void {
    this.service_galeria.get_galeriaImages().subscribe(data=>{
      console.log(data);
      this.images=data;
      this.contenido=data;
    })
    this.service_galeria.get_galeriaVideos().subscribe(data=>{
      this.videos=data
    })
  }

  handleClickVideos(){
    this.contenido=this.videos;
    this.imagenBool = false;
    this.videoBool = true;
  }

  handleClickImagen(){
    this.contenido=this.images;
    this.imagenBool = true;
    this.videoBool = false;
  }

  separateImage(){
    console.log(this.images.length)
    for (let index = 0; index < this.images.length; index++) {
      const element = [index];

    }
  }

  openVerticallyCentered(content, obj: Imagen | Videos) {
    this.objActuallity = obj
    this.modalService.open(content, { centered: true , scrollable: true});
  }

  slectImgVideo(obj: Imagen | Videos){
    this.objActuallity = obj
  }

}
