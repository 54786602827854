import { Component, OnInit } from '@angular/core';
import {PublicidadService} from './services/publicidad.service';
import {Publicidad} from './services/publicidad'
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-publicidad',
  templateUrl: './publicidad.component.html',
  styleUrls: ['./publicidad.component.css']
})
export class PublicidadComponent implements OnInit {
  public url: string = environment.apiUrl;
  public ads_vigentes: Publicidad[] = []
  public r_ad: Publicidad = {'id': 0, 'empresa':"", 'link': "", 'image': "", 'fecha_vencimiento': "", 'tipo':""};
  constructor(private publicidadService: PublicidadService) { }

  ngOnInit(): void {
    this.publicidadService.get_publicidades().subscribe((response)=>{
      let publicidades = response as Publicidad[];
      let len = publicidades.length
      let today = new Date()
      for(let i = 0; i < len; i++){
        let fecha_vencimiento = new Date(publicidades[i]['fecha_vencimiento'])
        if(today < fecha_vencimiento && publicidades[i]['tipo'] == 'LATERAL'){
          this.ads_vigentes.push(publicidades[i])
        }
      }
      this.r_ad = this.ads_vigentes[Math.floor(Math.random()*this.ads_vigentes.length)];
    });
  }

}
