import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute } from "@angular/router";
import { environment } from 'src/environments/environment';
import { TipsRecomendationsService } from '../tips-recomendations/tips-recomendations.service';
import { GaleriaService } from '../gallery/service/galeria.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Imagen } from '../gallery/interfaces';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { PublicidadService } from 'src/app/publicidad/services/publicidad.service';
import { Publicidad  } from 'src/app/publicidad/services/publicidad';
import { InformacionContactato } from './interfaces'
import { TemasService } from './services/temas.service'

declare var jQuery: any;

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.css',]
})
export class IndexComponent implements OnInit {
  public isLogged: Boolean = false;

  public objActuallity: Imagen;

  public publicidades: Publicidad[] = [];

  public adsURL: string = environment.imgUrl+'/media/ads/';

  public inforcionContacto: InformacionContactato = null

  constructor(public _http: HttpClient,
              private route: ActivatedRoute,
              private serviceTips: TipsRecomendationsService,
              private galeriaService: GaleriaService,
              private modalService: NgbModal,
              private publicidadService: PublicidadService,
              private temasService: TemasService
              ) {

  }

  images = [1, 2, 3, 4].map((n) => `assets/banners/banner${n}.jpg`);
  mostrar_donaciones = false;

  ngOnInit() {
    this.getGaleria();
    this.getValueLocal();
    //console.log("aquie");
    this.getTemasPrincipales(this._http);
    this.getNosotros(this._http);
    this.getTips(this._http);

    this.temasService.getInfomacionContacto().subscribe( (data) => {
      this.inforcionContacto = data
    })
    this._http.get(this.url+'/api/donacion_estado').subscribe((data)=>{
      if(data['status']){
        this.mostrar_donaciones = data['estado'] == 1;

      }
      console.log("DONACIONES: ", data)
    })



    this.publicidadService.get_publicidades().subscribe((data)=>{
      let publicidades = data as Publicidad[];
      let len = publicidades.length
      let today = new Date()
      for(let i = 0; i < len; i++){
        let fecha_vencimiento = new Date(publicidades[i]['fecha_vencimiento'])
        if(today < fecha_vencimiento && publicidades[i]['tipo'] == 'BANNER'){
          this.publicidades.push(publicidades[i])
        }
      }
    })


  }
  ngAfterViewInit(): void {
    (<any>window).twttr.widgets.load();
    (<any>window).widgets.createTimeline(
      {
        sourceType: "profile",
        screenName: "TwitterDev"
      },
      document.getElementById("container")
    );
  }

  public url = environment.apiUrl;

  month_list = ['ENE', 'FEB', 'MAR', 'ABR', 'MAY', 'JUN', 'JUL', 'AGO', 'SEP', 'OCT', 'NOV', 'DIC']

  getMonth(date: String) {
    let l = date.split("-", 3);
    let m: number = parseInt(l[1]);
    return this.month_list[m - 1];
  }

  getDay(date: String) {
    let l = date.split("-", 3);
    return l[2]
  }


  _date_day = ['14', '31', '15', '22']
  _date_month = ['MAR', 'MAY', 'MAR', 'MAR']
  temas = [];
  // persons: { [id: string] :  } = {};
  _temas: any = [];
  getTemasPrincipales(_http: HttpClient) {
    this._http.get(this.url + '/api/getPrincipalesTemas/')
      .subscribe(
        (data) => {
          this._temas = data;
          for (let key in data) {
            this.temas.push(data[key]);
          }
        }
        , (err: HttpErrorResponse) => { console.log("Un error ha ocurrido") }
        , () => console.log("")
      )
  }
  _nosotros: any;
  getNosotros(_http: HttpClient) {
    this._http.get(this.url + '/api/getNosotros/')
      .subscribe(
        (data) => {
          this._nosotros = data;
        }
        , (err: HttpErrorResponse) => { console.log("Un error ha ocurrido") }
        , () => console.log("solicitud finalizada OK")
      )
  }
  mediaUrl = environment.apiUrl + "/media/";
  tips: any[];
  getTips(_http: HttpClient) {
    this.serviceTips.get_tips().subscribe((data) => {
      let response = [];
      for (let i = 0; i < data.length; i++) {
        response[i] = data[data.length - 1 - i];
      }
      this.tips = response;
    },
      (error) => {
      }
    )
  }

  public _imagenes: any = [];
  public async getGaleria() {
    this.galeriaService.get_galeriaImages().subscribe((data) => {
      let count = 0;
      for (let i = data.length; i > 0; i--) {
        if (count == 5) break;
        let im = data[i - 1] as any;
        this._imagenes.push(im);
        count++;
      }
    })
  }

  getValueLocal() {
    let val = localStorage.getItem('isLogged')
    if (val == 'true') {
      this.isLogged = true;
    } else {
      this.isLogged = false;
    }
  }


  onClickImage(content, img: Imagen) {
    this.objActuallity = img;
    this.modalService.open(content, { centered: true, scrollable: true });

  }

  onClickAd(ad: Publicidad){
    window.open(ad.link, "_blank");
}





}
