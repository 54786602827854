import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class PasswordService {
  url = environment.apiUrl
  constructor(private http: HttpClient) { }

  post_solicitar_codigo(correo: string){
    return this.http.post(this.url+'/api/post_request_codigo_recuperacion/', {"correo": correo})
  }

  post_validar_codigo(correo: string, codigo: string){
    return this.http.post(this.url+"/api/post_validar_codigo_recuperacion/", {"correo": correo, "codigo": codigo})
  }

  post_update_pass_with_code(correo: string, codigo: string, hash: string, pass: string){
    return this.http.post(this.url+"/api/post_update_pass_with_code/",{"correo": correo, "codigo": codigo,"hash": hash,"password": pass})
  }
}
