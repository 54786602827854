import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { EventoService } from './services/evento.service';
import { Evento} from './services/evento';
import { environment } from 'src/environments/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-eventos',
  templateUrl: './eventos.component.html',
  styleUrls: ['./eventos.component.css']
})
export class EventosComponent implements OnInit {
  @ViewChild('content', { read: TemplateRef }) content_elem:TemplateRef<any>;
  public modalEvento: Evento;
  public eventos: Evento[];
  public imgURL = environment.imgUrl;
  constructor(private eventoService: EventoService, private modalService: NgbModal) { }

  ngOnInit(): void {
    this.eventoService.get_eventos().subscribe(data =>{
      this.eventos = data as Evento[];
      this.modalEvento = this.eventos[0];

      let evento_id = parseInt(localStorage.getItem('evento_id'));
      localStorage.setItem('evento_id', undefined);
      if(evento_id){
        for(let i = 0; i < this.eventos.length; i++){
          console.log('tuple',this.eventos[i].id, evento_id)
          if(this.eventos[i].id === evento_id){
            this.setModalEvento(this.content_elem, this.eventos[i])
          }
        }
      }
    })
  }

  setModalEvento(content, evento: Evento){
    this.modalEvento = evento;
    this.modalService.open(content, { centered: true , scrollable: true});
  }

}
