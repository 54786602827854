 <!--================Breadcrumb Area =================-->
 <section class="breadcrumb_area br_image " style="margin-top: 7rem !important;">
    <div class="container">
        <div class="page-cover text-center">
            <h2 class="page-cover-tittle">Políticas & Privacidad</h2>
            <ol class="breadcrumb">
                <li><a href="index.html">Inicio</a></li>
                <li class="active">Politicas</li>
            </ol>
        </div>
    </div>
</section>
<div class="whole-wrap">
    <div class="container">
        <div class="section-top-border">
            <div class="row">
                <div class="col-lg-12">
                    <blockquote class="generic-blockquote" innerHTML="{{politicas.Descripcion}}">
                    </blockquote>
                </div>
            </div>
        </div>
    </div>
</div>

