import { Component, OnInit } from '@angular/core';
import { TemaService } from '../temas-details/services/tema.service';

@Component({
  selector: 'app-temas-favoritos',
  templateUrl: './temas-favoritos.component.html',
  styleUrls: ['./temas-favoritos.component.css']
})
export class TemasFavoritosComponent implements OnInit {
  public temas_favoritos: any[] = [];
  public user_id;
  public isLogged = false;
  constructor(private service_tema:TemaService) { }

  ngOnInit(): void {
    this.getLogginStatus();
    //TODO: redirect if not logged
    this.service_tema.post_temas_favoritos(this.user_id).subscribe((data)=>{
      if(data['status']){
        this.temas_favoritos = data['temas'];
        console.log(this.temas_favoritos)
      }
    })
  }

  getLogginStatus() {
    let val = localStorage.getItem('isLogged')
    if (val == 'true') {
      this.isLogged = true;
      this.user_id = parseInt(localStorage.getItem('user_id'));
    } else {
      this.isLogged = false;
    }
  }

}
