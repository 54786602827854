import { Component, OnInit } from '@angular/core';
import { PasswordService } from './services/password.service'

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
  public error_msg = ""
  public step_msg = ""
  public showSpinner = false;
  
  public step = 1;
  public correo = "" 
  public codigo  = ""
  public hash = ""

  constructor(private passwordService: PasswordService) { }

  ngOnInit(): void {
  }

  solicitarCodigo(){
    let correo = (document.getElementById('correo_input') as HTMLInputElement).value
    if(correo == ""){
      this.error_msg = "Ingrese el asociado a su cuenta para poder continuar."
      return;
    }
    this.showSpinner = true;
    this.passwordService.post_solicitar_codigo(correo).subscribe((data)=>{
      this.showSpinner = false;
      if(data['status']){
        this.error_msg = ""
        this.step_msg = "Verifique su correo e ingrese el código que recibió acontinuación. No cierre ni recargue la página, de lo contrario el código caducará."
        this.correo = correo;
        this.step = 2;
      }else{
        this.step_msg = ""
        this.error_msg = data['message']
      }
    })
  }

  validarCodigo(){
    let codigo = (document.getElementById('codigo_input') as HTMLInputElement).value
    if(codigo == ""){
      this.error_msg = "Ingrese el código para poder continuar."
      return;
    }
    this.showSpinner = true;
    this.passwordService.post_validar_codigo(this.correo, codigo).subscribe((data)=>{
      this.showSpinner = false;
      if(data['status']){
        this.error_msg = ""
        this.step_msg = "Se ha validado el código, ingrese su nueva contraseña acontinuación."
        this.codigo = codigo;
        this.hash = data['hash']
        this.step = 3;
      }else{
        //this.step_msg =""
        this.error_msg = data['message'];
      }
    })
  }

  registrarContrasena(){
    let password = (document.getElementById('pass_input') as HTMLInputElement).value
    let repeatPassword = (document.getElementById('pass2_input') as HTMLInputElement).value
    if(password =="" || repeatPassword == ""){
      this.error_msg = "Complete todos los campos."
      return;
    }
    this.showSpinner = true;
    if(this.validarPasswords(password, repeatPassword)){
      this.passwordService.post_update_pass_with_code(this.correo, this.codigo, this.hash, password).subscribe((data)=>{
        this.showSpinner = false;
        if(data['status']){
          this.error_msg = ""
          this.step_msg = "Su contraseña ha sido actualizada con éxito. Ahora puede iniciar sesión."
          this.step = 4;
        }else{
          this.error_msg = "Ha ocurrido un error. Recargue la página y vuelva a intentar."
        }
      })
    }
  }

  validarPasswords(password: string, repeatPassword: string){
    if(password.length < 8 || repeatPassword.length < 8){
      this.error_msg = "La contraseña debe tener como mínimo 8 caracteres."
      this.showSpinner = false;
      return false; 
    }
    if(password != repeatPassword){
      this.error_msg = "La contraseña no coincide."
      this.showSpinner = false;
      return false; 
    }
    return true;
  }




}
