
    <header class="header_area mb-5">
        <div class="header_top" style="padding-top:0.5rem;">
            <div class="container">
                <div class="row">
                    <div class="col-sm-6 col-7">
                        <div class="top_btn d-flex ">
                            <!--a *ngIf="showProfile" [routerLink]="['/profile']">Mi Perfil</a>
                            <a *ngIf="showLogin" [routerLink]="['/login']" >Iniciar Sesión</a>
                            <a *ngIf="showClose" href="javascript.void(0)" (click)="logout()" >Cerrar Sesión</a>
                            <a href="#" routerLink="/donacion" >Donaciones</a-->
                            <ul style="margin-bottom: 0.3rem;">
                                <i class="bi bi-clock-fill white-icon"></i>
                                <span style="color: white; margin-left: 0.5rem;">Nuestros Horarios: 08:00 am - 18:00 pm</span>
                            </ul>

                        </div>
                    </div>
                    <div class="col-sm-6 col-5">
                        <ul class="nav justify-content-end" style=" text-align: center!important;">
                            <span style="color: white; margin-right: 1rem;">Ciudadela Coviem , Mz: 8 Villa 11. Teléfono: 0997007141</span>
                            <li style="text-align: center!important; margin-right: 0.5rem;"><a target="_blank" [href]="inforcionContacto.facebook"><i class="bi bi-facebook white-icon" style="padding-top: 10px;"></i></a></li>
                            <li style="margin-right: 0.5rem;"><a target="_blank" [href]="inforcionContacto.twitter"><i class="bi bi-twitter white-icon" style="padding-top: 10px;"></i></a></li>
                            <li><a target="_blank" [href]="inforcionContacto.instagram"><i class="bi bi-instagram white-icon" style="padding-top: 10px;"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div>

        <nav class="navbar navbar-expand-lg navbar-light">
            <div class="container">


                    <div *ngIf="showClose" class="dropdown" style="margin-top: auto; margin-bottom: auto;" id="profile-button-mobil">
                        <button class="btn dropdown-toggle"id="dropdownSesionButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i style="margin-top: auto; margin-bottom: auto;" class="bi bi-person-circle fa-2x"></i>
                        </button>

                        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownSesionButton" style="padding-top: 0px !important;padding-bottom: 0px !important; width: 322px;">
                            <!--div class="d-flex flex-column profile-foto">
                                <img [src]="apiURL+''+usuario.foto" class="rounded-circle">
                            </div-->
                            <!--a class="nav-link session-option" routerLink="/profile">Mi perfil</a-->
                            <app-profile-card [usuario]="usuario"></app-profile-card>
                            <div class="d-flex">
                                <button class="btn btn-outline-primary btn-secondary btn-block"(click)="logout()">
                                    <i class="bi bi-box-arrow-right"></i>
                                    Cerrar Sesión
                                </button>
                            </div>
                        </div>
                </div>

                <!-- Brand and toggle get grouped for better mobile display -->
                <a class="navbar-brand logo_h" href="index.html"><img src="assets/image/logo2.png"   alt=""></a>
                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="icon-bar"></span>
                    <span class="icon-bar"></span>
                    <span class="icon-bar"></span>
                </button>
                <!-- Collect the nav links, forms, and other content for toggling -->
                <div class="collapse navbar-collapse offset" id="navbarSupportedContent">
                    <ul class="nav navbar-nav menu_nav ml-auto">
                        <li [routerLinkActive]="['active']" class="nav-item"><a class="nav-link"  routerLink="/index">Inicio</a></li>
                        <li [routerLinkActive]="['active']" class="nav-item"><a class="nav-link" routerLink="/recomendacion">Tips</a></li>
                        <!--li [routerLinkActive]="['active']" class="nav-item"><a class="nav-link" routerLink="/testimonios">Testimonios</a></li-->
                        <!--li [routerLinkActive]="['active']" class="nav-item"><a class="nav-link" routerLink="/donacion">Donaciones</a></li-->
                        <li [routerLinkActive]="['active']" class="nav-item"><a class="nav-link"  routerLink="/galeria">Galería</a></li>
                        <!--li [routerLinkActive]="['active']" class="nav-item"><a class="nav-link" routerLink="/nosotros">Nosotros</a></li-->
                        <li [routerLinkActive]="['active']" class="nav-item"><a class="nav-link" routerLink="/contact">Contáctanos</a></li>
                        <li [routerLinkActive]="['active']" class="nav-item"><a class="nav-link"  routerLink="/eventos">Eventos</a></li>
                        <!--li *ngIf="showLogin" [routerLinkActive]="['active']" class="nav-item"><a class="nav-link" routerLink="/profile">Mi perfil</a></li-->
                        <li *ngIf="showLogin" [routerLinkActive]="['active']" class="nav-item"><a class="nav-link" routerLink="/login">Iniciar Sesión</a></li>

                        <div *ngIf="showClose" class="d-flex dropdown" id="profile-button-web">
                            <div class="dropdown" style="margin-top: auto; margin-bottom: auto;">
                                <button class="btn dropdown-toggle"id="dropdownNotificationsButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i style="margin-top: auto; margin-bottom: auto;" class="bi bi-bell-fill fa-2x"></i>
                                </button>

                                <div class="dropdown-menu dropdown-menu-right not-div" aria-labelledby="dropdownNotificationsButton">
                                    <!--a class="nav-link session-option" routerLink="/profile">Mi perfil</a-->

                                        <div *ngFor="let notificacion of all_notifications" class="d-flex flex-row px-4 py-3 align-items-center dropdown-item" (click)="onNotificacionClicked(notificacion)">
                                            <div class=" pr-3">

                                                <i *ngIf="notificacion.tipo=='EVENTO'" class="bi bi-calendar-event fa-2x"></i>
                                                <i *ngIf="notificacion.tipo=='TEMA'" class="bi bi-newspaper fa-2x"></i>
                                                <i *ngIf="notificacion.tipo=='CONSEJERIA'" class="bi bi-clipboard2-pulse fa-2x"></i>
                                            </div>
                                            <div>
                                                <div class="d-flex flex-column" style=" min-width: 400px;">
                                                    <div>
                                                        <div class="d-flex justify-content-between">
                                                            <div>
                                                                <div class="d-flex justify-content-left align-items-center">
                                                                    <span *ngIf="notificacion.tipo=='EVENTO'" style="font-weight: bold;">Nuevo evento anuciado</span>
                                                                    <span *ngIf="notificacion.tipo=='TEMA'" style="font-weight: bold;">Nuevo tema publicado</span>
                                                                    <span *ngIf="notificacion.tipo=='CONSEJERIA'" style="font-weight: bold;">Nueva asesoría registrada</span>
                                                                    <div *ngIf="!notificacion.seen" class="rounded-circle unseen-circle"></div>
                                                                </div>

                                                            </div>

                                                            <span>{{notificacion.fecha_creacion}}</span>
                                                        </div>
                                                    </div>
                                                    <span *ngIf="notificacion.tipo=='EVENTO'">Planificado para: {{notificacion.fecha}} a las {{notificacion.hora}}</span>
                                                    <span *ngIf="notificacion.tipo=='TEMA'">Tema publicado el {{notificacion.fecha}}</span>
                                                    <span *ngIf="notificacion.tipo=='CONSEJERIA'">Solicitada para el {{notificacion.fecha}} a las {{notificacion.hora}}</span>
                                                    <span> Haz click para más información</span>
                                                </div>
                                            </div>
                                        </div>
                                </div>
                            </div>

                            <div class="dropdown" style="margin-top: auto; margin-bottom: auto;">
                                <button class="btn dropdown-toggle"id="dropdownSesionButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i style="margin-top: auto; margin-bottom: auto;" class="bi bi-person-circle fa-2x"></i>
                                </button>

                                <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownSesionButton" style="padding-top: 0px !important;padding-bottom: 0px !important;">
                                    <!--div class="d-flex flex-column profile-foto">
                                        <img [src]="apiURL+''+usuario.foto" class="rounded-circle">
                                    </div-->
                                    <!--a class="nav-link session-option" routerLink="/profile">Mi perfil</a-->
                                    <app-profile-card [usuario]="usuario"></app-profile-card>
                                    <div class="d-flex">
                                        <button class="btn btn-outline-primary btn-secondary btn-block"(click)="logout()">
                                            <i class="bi bi-box-arrow-right"></i>
                                            Cerrar Sesión
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>



                    </ul>

                </div>

            </div>
        </nav>
       </div>
    </header>
