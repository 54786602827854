<div class="mt-4 mx-4">
  <h2 class="text-align-center">Asesorías Agendadas</h2>
  <table class="table table-striped">
    <thead>
      <tr>
        <th scope="col">ASESOR</th>
        <th scope="col">TEMA</th>
        <th scope="col">MODALIDAD</th>
        <th scope="col">LINK/DIRECCION</th>
        <th scope="col">FECHA</th>
        <th scope="col">HORA</th>
        <th scope="col">OPCIONES</th>
      </tr>
    </thead>

    <tbody id="table-body-pendientes">
      <tr *ngFor="let consejeria of consejeriasPendientes | async">
        <td>{{consejeria.consejero.nombre}}</td>
        <td>{{consejeria.tema.nombre}}</td>
        <td>{{consejeria.modalidad}}</td>
        <!-- <td>{{(consejeria.direccion == "") ? consejeria.link : consejeria.direccion}}</td> -->
        <td *ngIf="consejeria.modalidad == 'ONLINE'; else dir">{{consejeria.link}}</td>
        <ng-template #dir><td>{{consejeria.direccion}}</td></ng-template>
        <td>{{consejeria.fecha}}</td>
        <td>{{consejeria.hora}}</td>
        <td>
          <button class='btn btn-primary'
            (click)="handleClickEditar(consejeria,contentFormularioEditar,contentmensaje)">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pen"
              viewBox="0 0 16 16">
              <path
                d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001zm-.644.766a.5.5 0 0 0-.707 0L1.95 11.756l-.764 3.057 3.057-.764L14.44 3.854a.5.5 0 0 0 0-.708l-1.585-1.585z" />
            </svg>
          </button>
          <button class='btn btn-danger' (click)="handleClickEliminar(consejeria.id, contentmensaje)">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash"
              viewBox="0 0 16 16">
              <path
                d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
              <path fill-rule="evenodd"
                d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
            </svg>
          </button>
        </td>
      </tr>
    </tbody>

  </table>

  <div class="d-flex justify-content-between p-2">
    <ngb-pagination [collectionSize]="consejeriasPendientesSize" [(page)]="page1" [pageSize]="pageSize1"
      (pageChange)="refreshPendientes()">
    </ngb-pagination>

    <select class="form-select" style="width: auto" [(ngModel)]="pageSize1" (ngModelChange)="refreshPendientes()">
      <option [ngValue]="0">Escoja cuantas filas mostrar</option>
      <option [ngValue]="2">2 items per page</option>
      <option [ngValue]="4">4 items per page</option>
      <option [ngValue]="6">6 items per page</option>
    </select>
  </div>

  <h2 class="text-align-center mt-5 mb-6">Asesorías Realizadas</h2>
  <table class="table table-striped">
    <thead>
      <tr>
        <th scope="col">ASESOR</th>
        <th scope="col">TEMA</th>
        <th scope="col">MODALIDAD</th>
        <th scope="col">LINK/DIRECCION</th>
        <th scope="col">FECHA</th>
        <th scope="col">HORA</th>
      </tr>
    </thead>

    <tbody id="table-body-terminadas">
      <tr *ngFor="let consejeria of consejeriasTerminadas | async">
        <td>{{consejeria.consejero.nombre}}</td>
        <td>{{consejeria.tema.nombre}}</td>
        <td>{{consejeria.modalidad}}</td>
        <!-- <td>{{(consejeria.direccion == "") ? consejeria.link : consejeria.direccion}}</td> -->
        <td *ngIf="consejeria.modalidad == 'ONLINE'; else dir2">No disponible</td>
        <ng-template #dir2><td>{{consejeria.direccion}}</td></ng-template>
        <td>{{consejeria.fecha}}</td>
        <td>{{consejeria.hora}}</td>
      </tr>
    </tbody>

  </table>

  <div class="d-flex justify-content-between p-2">
    <ngb-pagination [collectionSize]="consejeriasTerminadasSize" [(page)]="page2" [pageSize]="pageSize2"
      (pageChange)="refreshTerminadas()">
    </ngb-pagination>

    <select class="form-select" style="width: auto" [(ngModel)]="pageSize2" (ngModelChange)="refreshTerminadas()">
      <option [ngValue]="0">Escoja cuantas filas mostrar</option>
      <option [ngValue]="2">2 items per page</option>
      <option [ngValue]="4">4 items per page</option>
      <option [ngValue]="6">6 items per page</option>
    </select>
  </div>

  <!-- <ng-template #contentFormularioEditar let-modal >
    <form [formGroup]="formConsejeria">
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Editar Consejeria</h4>
      <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">

        <div class="form-group col-12 mb-4">
          <select
            class="form-control"
            name="modalidad_consejeria"
            id="modalidad_consejeria"
            formControlName="optionSelectModalidad"
            required
          >
            <option disabled selected value="" >Seleccione Modalidad</option>
            <ng-template ngFor let-mod [ngForOf]="modalidad">
              <option [ngValue]="mod">{{mod}}</option>
            </ng-template>
          </select>
        </div>
        <div class="form-group col-12 mb-4">
          <select
            class="form-control"
            name="tema_consejeria"
            id="tema_consejeria"
            formControlName="optionSelectTemaConsejeria"
            required
          >
            <option disabled selected [ngValue]="null" >Seleccione un tema</option>
            <ng-template ngFor let-tema [ngForOf]="temaConsjeria">
              <option [ngValue]="tema.id">{{tema.nombre}}</option>
            </ng-template>
          </select>
        </div>
        <div class="form-group col-12 mb-4">
          <div class="input-group">
            <input
              type="text"
              formControlName="optiondate"
              class="form-control"
              placeholder="yyyy-mm-dd"
              name="dp"
              id="dp"
              ngbDatepicker
              [minDate]="{year: 2022, month: 1, day: 1}"
              [maxDate]="{year: 2048, month: 12, day: 31}"
              #d="ngbDatepicker"
              [markDisabled]="isDisabled"
              (dateSelect)="onDateSelect($event)"
              required
            />
            <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-calendar3" viewBox="0 0 16 16">
                <path d="M14 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zM1 3.857C1 3.384 1.448 3 2 3h12c.552 0 1 .384 1 .857v10.286c0 .473-.448.857-1 .857H2c-.552 0-1-.384-1-.857V3.857z"/>
                <path d="M6.5 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
              </svg>
            </button>
          </div>
        </div>
        <div class="form-group col-xl-6 col-md-12 mb-4">
          <div class="d-flex">
            <select
              class="form-control col-6"
              name="hours"
              id="hours"
              formControlName="optionSelectHour"
              required
            >
              <option disabled selected value="" >Hora</option>
              <ng-template ngFor let-hour [ngForOf]="hours">
                <option *ngIf="hour < 10" [ngValue]="hour">0{{hour}}:00</option>
                <option *ngIf="hour >= 10" [ngValue]="hour">{{hour}}:00</option>
              </ng-template>
            </select>
          </div>
        </div>
        <div class="form-group col-12 mb-3">
          <label for="motivo">Motivo de la consejeria</label>
          <textarea
            class="form-control"
            name="motivo"
            for="motivo"
            formControlName="optionMotivo"
            required
            rows="3"
            height="10"
          >
          </textarea>
        </div>
    </div>
    <div class="modal-footer">
      <button [disabled]="formConsejeria.invalid" type="button" class="btn btn-primary" (click)="modal.close('Save click')">Aceptar</button>
    </div></form>
  </ng-template> -->

  <ng-template #contentmensaje let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">{{mensaje}}</h4>
      <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
      <p>{{descripcionMensaje}}</p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary" (click)="modal.close('Save click')">Aceptar</button>
    </div>
  </ng-template>
</div>

<ng-template #contentFormularioEditar let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Editar asesoría</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <app-modificar-cita [consejeria]="consejeria_editar"></app-modificar-cita>
  </div>
</ng-template>