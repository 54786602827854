<body style="background-color: #666666;">	
	<div class="limiter">
		<div class="container-login100">
			<div class="wrap-login100">
				<form class="login100-form validate-form">
					<span class="login100-form-title p-b-43">
						Recuperar Contraseña
					</span>

					<div class="d-flex">
						<div class="mb-4"style="margin: auto">
							<div *ngIf="showSpinner" class="spinner-border text-primary" role="status">
								<span class="sr-only">Loading...</span>
							</div>
						</div>
					</div>

					<span *ngIf="step_msg != ''" class="mt-1 mb-1">{{step_msg}}</span>
					
					<div *ngIf="step==1" class="wrap-input100 validate-input" data-validate = "Valid email is required: ex@abc.xyz">
						<input #correo_input id="correo_input" class="input100" type="email" name="username" placeholder="Correo"  >
						<span class="focus-input100"></span>
						<span class="label-input100"></span>
					</div>
					<div *ngIf="step==2" class="wrap-input100 validate-input" data-validate = "Valid email is required: ex@abc.xyz">
						<input #codigo_input id="codigo_input" class="input100" type="text" name="username" placeholder="****"  >
						<span class="focus-input100"></span>
						<span class="label-input100"></span>
					</div>
					<div *ngIf="step==3" class="wrap-input100 validate-input" data-validate = "Valid email is required: ex@abc.xyz">
						<input #pass_input id="pass_input" class="input100" type="password" name="username" placeholder="Nueva contraseña"  >
						<span class="focus-input100"></span>
						<span class="label-input100"></span>
					</div>
					<div *ngIf="step==3" class="wrap-input100 validate-input" data-validate = "Valid email is required: ex@abc.xyz">
						<input #pass2_input id="pass2_input" class="input100" type="password" name="username" placeholder="Repetir contraseña"  >
						<span class="focus-input100"></span>
						<span class="label-input100"></span>
					</div>
					<span *ngIf="error_msg != ''" style="color:red;" class="mt-2 mb-2">{{error_msg}}</span>

					<div *ngIf="step==1" class="container-login100-form-btn">
						<button class="login100-form-btn" (click)="solicitarCodigo()" >
							Obtener código
						</button>
					</div>
					<div *ngIf="step==2" class="container-login100-form-btn">
						<button class="login100-form-btn" (click)="validarCodigo()" >
							Contnuar
						</button>
					</div>
					<div *ngIf="step==3" class="container-login100-form-btn">
						<button class="login100-form-btn" (click)="registrarContrasena()" >
							Contnuar
						</button>
					</div>
					<div *ngIf="step==4" class="container-login100-form-btn">
						<button class="login100-form-btn">
							<a  href="#/login" style="color: white;">
								Iniciar Sesión
							</a>
						</button>
					</div>
					

					<div class="text-center p-t-46 p-b-20">
						<span class="txt2">
							Regresar
						</span>
					</div>

					<div class="login100-form-social flex-c-m">
						<a href="#" class="login100-form-social-item flex-c-m bg1 m-r-5">
							<i class="fa fa-chevron-left" aria-hidden="true"></i>
						</a>

						
					</div>
				</form>
				<div class="login100-more" style="background-image: url('../../../assets/image/recuperar.jpg');">
				</div>
			</div>y
		</div>
</div>