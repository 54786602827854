<div>
    <div class="mb-3">
        <select class="form-control" id="modalidad-select">
            <option disabled selected [value]="null">Seleccione una modalidad</option>
            <option value="PRECENCIAL">PRECENCIAL</option>
            <option value="ONLINE">ONLINE</option>
        </select>
    </div>

    <div class="mb-3">
        <select class="form-control" id="tema-select">
            <option disabled selected [value]="null">Seleccione un tema</option>
            <option *ngFor="let tema of temas" [value]="tema.id">{{tema.nombre}}</option>
        </select>
    </div>

    <div class="mb-3">
        <input type="date" class="form-control" id="fecha-input" [min]="min_date" [value]="consejeria.fecha" (change)="onChangeDate()">
    </div>

    <div class="mb-3">
        <select class="form-control" id="horario-select">
            <option disabled selected [value]="null">Seleccione un horario</option>
            <option *ngFor="let hora of horas" [value]="hora">{{hora}}</option>
        </select>
    </div>

    <div class="form-group mb-3">
        <textarea #motivo_input class="form-control" id="motivo-input" rows="3" placeholder="Ingrese su motivo"></textarea>
    </div>
    <span>Antes de guardar verifique sus cambios!</span>
    <div>
        <button class="btn btn-danger mr-2" (click)="close_modal()">
            Cancelar
        </button>
        <button class="btn btn-primary" (click)="onSubmit()">
            Guardar cambios
        </button>
    </div>
</div>
