<body style="background-color: #666666;">
    <div class="limiter">
        <div class="container-login100">
            <div class="wrap-login100">
                <form class="login100-form validate-form" [formGroup]="registerForm" (ngSubmit)="register()">
                    <span class="login100-form-title p-b-43">
                        Registrarse
                    </span>

                    <div *ngIf="success">
                        <h4>Usuario registrado exitosamente. Ahora puede iniciar sesión.</h4>
                        <a href="#/login">
                            <button type="button" class="login100-form-btn">Iniciar sesión</button>
                        </a>
                        
                    </div>

                    <div *ngIf="!success">
                        <div class="wrap-input50 validate-input" data-validate="Valid email is required: ex@abc.xyz">
                            <input class="input50" type="text" placeholder="Nombre" formControlName="nombre"
                                [ngClass]="{ 'is-invalid': submitted && f.nombre.errors }">
                            <span class="focus-input50"></span>
                            <span class="label-input50"></span>
                        </div>
                        <div *ngIf="submitted && f.nombre.errors" class="invalid-feedback">
                            <div *ngIf="f.nombre.errors.required">El nombre es requerido</div>
                        </div>
    
                        <div class="wrap-input50 validate-input" data-validate="Valid email is required: ex@abc.xyz">
                            <input class="input50" type="text" placeholder="Apellido" formControlName="apellido"
                                [ngClass]="{ 'is-invalid': submitted && f.apellido.errors }">
                            <span class="focus-input50"></span>
                            <span class="label-input50"></span>
                        </div>
                        <div *ngIf="submitted && f.apellido.errors" class="invalid-feedback">
                            <div *ngIf="f.apellido.errors.required">El apellido es requerido</div>
                        </div>
    
                        <div class="wrap-input50 validate-input" data-validate="Valid email is required: ex@abc.xyz">
                            <input id="telefono-input" class="input50" type="text" placeholder="Teléfono"
                                formControlName="telefono" [ngClass]="{ 'is-invalid': submitted && f.telefono.errors}">
                            <span class="focus-input50"></span>
                            <span class="label-input50"></span>
                        </div>
                        <div *ngIf="submitted && f.telefono.errors" class="invalid-feedback">
                            <div *ngIf="f.telefono.errors.required">El telefono es requerido</div>
                        </div>
    
                        <div class="wrap-input50 validate-input" data-validate="Valid email is required: ex@abc.xyz">
                            <input class="input50" type="text" placeholder="Correo" formControlName="correo"
                                [ngClass]="{ 'is-invalid': submitted && f.correo.errors }">
                            <span class="focus-input50"></span>
                            <span class="label-input50"></span>
                        </div>
                        <div *ngIf="submitted && f.correo.errors" class="invalid-feedback">
                            <div *ngIf="f.correo.errors.required">Email es requerido</div>
                            <div *ngIf="f.correo.errors.email">Email debe de ser una dirección valida</div>
                        </div>
    
                        <div class="wrap-input50 validate-input" data-validate="Valid email is required: ex@abc.xyz">
                            <input class="input50" type="text" placeholder="Usuario" formControlName="username"
                                [ngClass]="{ 'is-invalid': submitted && f.username.errors }">
                            <span class="focus-input50"></span>
                            <span class="label-input50"></span>
                        </div>
                        <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                            <div *ngIf="f.username.errors.required">El usuario es requerido</div>
                        </div>
    
    
                        <div class="wrap-input50 validate-input" data-validate="Password is required" style="position: relative">
                            <input class="input50" type="password" placeholder="Contraseña" formControlName="password"  id="password"
                                [ngClass]="{ 'is-invalid': submitted && f.password.errors }" [(ngModel)]="password">
                            <div class="mx-2 my-2" style="position: absolute; right: 3%; top: 1%">
                                <span id="hidePass" style="font-size: 1.5rem;;" class="z-10 fa fa-fw fa-eye password-icon show-password" (click)="clickViewPassword()"></span>
                            </div>
                            <span class="focus-input50"></span>
                            <span class="label-input50"></span>
                        </div>

                        <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                            <div *ngIf="f.password.errors.required">La contraseña es requerida</div>
                        </div>
    
    
                        <div class="container-login100-form-btn">
                            <button type="submit" class="login100-form-btn">
                                Registrarme
                            </button>
                        </div>
    
                        <div class="text-center p-t-46 p-b-20">
                            <span class="txt2">
                                Regresar
                            </span>
                        </div>
                        <div class="login100-form-social flex-c-m">
                            <a href="#" class="login100-form-social-item flex-c-m bg1 m-r-5">
                                <i class="fa fa-chevron-left" aria-hidden="true"></i>
                            </a>
                        </div>
                    </div>

                    

                </form>
                





                <div class="login100-more" style="background-image: url('../../../assets/image/familia.jpg');">
                </div>
            </div>
        </div>
    </div>