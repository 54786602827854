<header class="header_area"></header>
<!--================Header Area =================-->
<!--================Breadcrumb Area =================-->
<section class="breadcrumb_area blog_banner_two ">
    <div class="overlay bg-parallax" data-stellar-ratio="0.8" data-stellar-vertical-offset="0" data-background=""></div>
    <div class="container">
        <div class="page-cover text-center">
            <h2 class="page-cover-tittle f_48">Temas guardados como favoritos</h2>
            <!--ol class="breadcrumb">
                <li><a href="index.html">Home</a></li>
                <li><a href="blog.html">Tema</a></li>
            </ol-->
        </div>
    </div>
</section>
<div class="container mt-5 mb-5">
    <div class="d-flex flex-row flex-wrap justify-content-around">
        <div class="block-div" *ngFor="let tema of temas_favoritos;" style="margin-top: 15px">
            <app-tema-card  [tema]="tema"></app-tema-card>
        </div>
        
    </div>
</div>
