<header class="header_area"></header>
<section class="breadcrumb_area br_image ">
  <div class="container">
    <div class="page-cover text-center">
      <h2 class="page-cover-tittle">Próximos eventos</h2>
      <!--ol class="breadcrumb">
                <li><a href="index.html">Inicio</a></li>
                <li class="active">Eventos</li>
            </ol-->
    </div>
  </div>
</section>
<section>
  <div class="container" style="margin-top: 4rem;">
    <div class="d-flex flex-row flex-wrap justify-content-around align-items-stretch">
      <div *ngFor="let evento of eventos; let i = index;" class="card mb-5" style="width: 18rem;">
        <div class="card-img-top-div">
          <img *ngIf="evento.image != null; else elseImg" class="card-img-top" src="{{imgURL}}/media/{{evento.image}}"
            alt="Card image cap">
          <ng-template #elseImg><img class="card-img-top" src="{{imgURL}}/media/image/evento_default.png"
              alt="Card image cap"></ng-template>
        </div>

        <div class="card-body" style="max-height: 12rem;">
          <h5 class="card-title">{{evento.titulo}}</h5>
          <p class="card-text cut-text">{{evento.descripcion}}</p>
          <button (click)="setModalEvento(content, evento)" class="btn btn-primary">Detalles</button>
        </div>
      </div>
    </div>
  </div>
</section>


<ng-template #content id="content-modal" let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="eventoModalLabel">EVENTO</h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <div class="row justify-content-center">
      <div class="column">
        <h3>{{modalEvento.titulo}}</h3>
      </div>
    </div>
    <div class="row">
      <div class="column modal-img-div">
        <img *ngIf="modalEvento.image != null; else elseImgModal" class="modal-img"
          src="{{imgURL}}/media/{{modalEvento.image}}">
        <ng-template #elseImgModal><img class="card-img-top" src="{{imgURL}}/media/image/evento_default.png"
            alt="Card image cap"></ng-template>
      </div>
    </div>

    <div class="row mx-1 my-1">
      <div class="column">
        <h5>Fecha&nbsp;</h5>
      </div>
      <div class="column">{{modalEvento.fecha_evento}}</div>
    </div>
    <div class="row mx-1 my-1">
      <div class="column">
        <h5>Inicia a las&nbsp;</h5>
      </div>
      <div class="column">{{modalEvento.hora_inicio}}</div>
    </div>
    <div *ngIf="modalEvento.hora_fin != null" class="row mx-1 my-1">
      <div class="column">
        <h5>Termina a las&nbsp;</h5>
      </div>
      <div class="column">{{modalEvento.hora_fin}}</div>
    </div>
    <div class="row mx-1 my-1">
      <div class="column">
        <h5>Dirección:&nbsp;</h5>
      </div>
      <div class="column">{{modalEvento.direccion}}</div>
    </div>
    <div class="row mx-1 my-1 justify-content-center">
      <div class="column">{{modalEvento.descripcion}}</div>
    </div>

  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="modal.close('Close click')">
      Close
    </button>
    <!--button type="button" class="btn btn-primary">Save changes</button-->
  </div>
</ng-template>