import { Component, OnInit } from '@angular/core';
import { RegisterService } from './register.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  registerForm: FormGroup;
  submitted = false;
  showMessage = false;


  constructor(private registerService: RegisterService, private formBuilder: FormBuilder, private router: Router) { }

  ngOnInit(): void {
    this.registerForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
      correo: ['', [Validators.required, Validators.email]],
      nombre: ['', Validators.required],
      apellido: ['', [Validators.required]],
      telefono: ['', [Validators.required]]
    })
  }

  // convenience getter for easy access to form fields
  get f() { return this.registerForm.controls; }

  success: boolean = false;

  register() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.registerForm.invalid) {
      return;
    }
    if (!this.checkValidNumber((document.getElementById('telefono-input') as HTMLInputElement).value)) {
      alert('Ingrese un número de teléfono válido');
      return;
    }
    this.showMessage = true;
    this.registerService.registrar(this.registerForm.value).subscribe(
      (data) => {
        console.log("OK");
        this.onReset();
        //alert("Usuario registrado exitosamente! Ahora puede iniciar sesión.")
        this.success = true;
      },
      (error) => {
        console.log(error);
      }


    );
  }

  gotoLogin() {
    this.router.navigate(['#/login'])
  }

  checkValidNumber(str: string) {
    if (str[0] != '0') return false;
    if (str.length != 10) return false;
    return /^[0-9]/.test(str)
  }

  onReset() {
    this.submitted = false;
    this.registerForm.reset();
  }


  clickViewPassword() {
    let hidePass = document.getElementById("hidePass")
    let password = document.getElementById("password") as HTMLInputElement
    if (password.type === "text") {
      password.type = "password";
      hidePass.classList.remove('fa-eye-slash');
    }
    else {
      password.type = "text";
      hidePass.classList.toggle("fa-eye-slash");
    }
  }
}
