import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ProfileInformation } from 'src/app/common/header/services/notificacion';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-profile-card',
  templateUrl: './profile-card.component.html',
  styleUrls: ['./profile-card.component.css']
})
export class ProfileCardComponent implements OnInit {
  apiURL: string = environment.apiUrl
  @Input() usuario?: ProfileInformation;
  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  redirectToProfileInformation(){
    console.log(this.router.url);
    localStorage.setItem('profile_option','PROFILE');
    if(this.router.url  == '/profile'){
      window.location.reload();
    }
    this.router.navigate(['/profile']);
  }
  redirectTo_setInfo(){
    localStorage.setItem('profile_option','SET_INFO');
    if(this.router.url  == '/profile'){
      window.location.reload();
    }
    this.router.navigate(['/profile']);
  }
  redirectTo_SetFoto(){
    localStorage.setItem('profile_option','SET_FOTO');
    if(this.router.url  == '/profile'){
      window.location.reload();
    }
    this.router.navigate(['/profile']);
  }
  redirectTo_SetPassword(){
    localStorage.setItem('profile_option','SET_PASSWORD');
    if(this.router.url  == '/profile'){
      window.location.reload();
    }
    this.router.navigate(['/profile']);
  }

  redirectToFavoritos(){

  }

  redirecToCalendar(){

  }
}
