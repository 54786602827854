import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ViewEncapsulation,
} from '@angular/core';



@Component({
  selector: 'mwl-demo-component',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './calendar.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class CalendarComponent implements OnInit {

  vistaTemplate: string = "HISTORIAL"

  constructor(
  ){}

  ngOnInit(): void {
  }

  handleClicKAgendar() {
    this.vistaTemplate = 'AGENDAR'
  }

  handleClickHistorial() {
    this.vistaTemplate = 'HISTORIAL'
  }

}
