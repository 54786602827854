import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-tema-card',
  templateUrl: './tema-card.component.html',
  styleUrls: ['./tema-card.component.css']
})
export class TemaCardComponent implements OnInit {
  @Input() tema?: any;
  constructor() { }

  ngOnInit(): void {
  }

  public url = environment.apiUrl;

  month_list = ['ENE', 'FEB', 'MAR', 'ABR', 'MAY', 'JUN', 'JUL', 'AGO', 'SEP', 'OCT', 'NOV', 'DIC']

  getMonth(date: String) {
    let l = date.split("-", 3);
    let m: number = parseInt(l[1]);
    return this.month_list[m - 1];
  }

  getDay(date: String) {
    let l = date.split("-", 3);
    return l[2]
  }

  formatAutor(autor: string){
    let l = autor.split(" ");
    let format = l[0];
    format = format + " " + l[1][0];
    return format;
  }

}
