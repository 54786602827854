import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/components/login/login.service';
import { NotificacionesService} from 'src/app/common/header/services/notificaciones.service'
import { Notificacion, ProfileInformation, SeenNotificacion } from 'src/app/common/header/services/notificacion'
import { TemaService } from 'src/app/components/temas-details/services/tema.service';
import { environment } from 'src/environments/environment.prod';
import { InformacionContactato } from 'src/app/components/index/interfaces';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  apiURL: string = environment.apiUrl

  showProfile:boolean=false;
  showLogin:boolean=true;
  showClose:boolean=false;

  isLogged: boolean = false;
  user_id: number = null;

  usuario: ProfileInformation = null;

  all_notifications: Notificacion[] = []
  seen_notifications: Notificacion[] = []

  public inforcionContacto: InformacionContactato = null

  constructor(
    public router: Router,
    public service_login: LoginService,
    private notificacionesService: NotificacionesService,
    private temaService: TemaService,
  ) { }

  ngOnInit(): void {
    this.getValueLocal();
    this.getLogginStatus();
    console.log('isLogged?:',this.isLogged);
    if(this.isLogged){
      this.notificacionesService.post_profile_information(this.user_id).subscribe((data)=>{
        console.log('PROFILE:' , data)
        this.usuario = data as ProfileInformation;
      })
    }
    this.notificacionesService.get_notificaciones(this.user_id).subscribe((data)=>{
      //this.all_notifications = data['notificaciones'] as Notificacion[];
      let n = data['notificaciones'] as Notificacion[];
      for(let i = n.length; i > 0; i--){
        this.all_notifications.push(n[i-1]);
        if(this.all_notifications.length == 20) break;
      }
      let vistas = data['notificacionUsuario'] as SeenNotificacion[];
      for(let i = 0; i < this.all_notifications.length; i++){
        this.all_notifications[i].seen = false;
        for(let j = 0; j < vistas.length; j++){
          if(this.all_notifications[i].id == vistas[j].notificacion_id){
            this.all_notifications[i].seen = true;
          }
        }
      }
    })
    this.notificacionesService.getInfomacionContacto().subscribe( (data) => {
      this.inforcionContacto = data
    })
  }



  getValueLocal(){
    let val = localStorage.getItem('isLogged')
    if(val=='true'){
      this.showProfile= true;
      this.showClose=true;
      this.showLogin=false;
    }else{
      this.showProfile= false;
      this.showLogin=true;
      this.showClose=false;
    }
  }

  logout(){
    this.service_login.logout();
    window.location.reload();
    console.log(this.service_login.authInstance.currentUser.get())
  }

  getLogginStatus() {
    let val = localStorage.getItem('isLogged')
    if (val == 'true') {
      this.isLogged = true;
      this.user_id = parseInt(localStorage.getItem('user_id'));
    } else {
      this.isLogged = false;
    }
  }

  onNotificacionClicked(not: Notificacion){
    console.log(not);
    switch (not.tipo){
      case 'EVENTO':
        localStorage.setItem('evento_id',not.id_tipo.toString());
        this.router.navigate(['/eventos']);
        break;
      case 'CONSEJERIA':
        this.router.navigate(['/calendar']);
        break;
      case 'TEMA':
        this.temaService.get_categoria_by_tema(not.id_tipo).subscribe((data)=>{
          if(data['status']){
            this.router.navigate([`/tema-details/${not.id_tipo}/${data['id_categoria']}`]);
          }
        });

        break;
    }
    /*this.notificacionesService.post_checkNotificacion(this.user_id, not.id).subscribe((data)=>{
      console.log(data);
    })*/
  }

}

