<div id="carousel-tips" class="carousel slide" data-ride="carousel">
    <div *ngIf="ads_vigentes.length != 0" class="carousel-inner" style="min-height: 130px;">
        <div class="carousel-item active">
            <div class="mb-3" style="max-width: 300px;">
                <div style="position: relative; width:300px; margin: auto;">
                    <img id="displayed-image" src="{{url}}/media/ads/{{r_ad.image}}" alt="your image" width="300px"
                        height="300px" />
                    <button
                        style="position: absolute; top:0% ; left: 0px; height: 20px; width: 30px; font-size: 10px; border-radius: 5px;">Ad</button>
                    <button
                        style="position: absolute; top:0% ; right: 20px; height: 20px; width: 20px; font-size: 10px;">?</button>
                    <button
                        style="position: absolute; top:0% ; right: 0px; height: 20px; width: 20px; font-size: 10px;">X</button>
                </div>
                <div class="card" style="width: 18rem; border-radius: 0px; width: 300px; height: 50px; margin: auto;">
                    <div class="card-body" style="width: 300px; height: 60px; padding: 10px 20px 0px 20px;">
                        <div style="display:flex; flex-direction:row; justify-content: space-between;">
                            <span id="ad-preview-empresa" class="card-title"
                                style="font-size: 1.2rem">{{r_ad.empresa}}</span>
                            <a href="{{r_ad.link}}" target="_blank" class="card-link" style="font-size: 1.2rem">Visítanos</a>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
        <div *ngFor="let ad of ads_vigentes" class="carousel-item">

            <div class="mb-3" style="max-width: 300px;">
                <div style="position: relative; width:300px; margin: auto;">
                    <img id="displayed-image" src="{{url}}/media/ads/{{ad.image}}" alt="your image" width="300px"
                        height="300px" />
                    <button
                        style="position: absolute; top:0% ; left: 0px; height: 20px; width: 30px; font-size: 10px; border-radius: 5px;">Ad</button>
                    <button
                        style="position: absolute; top:0% ; right: 20px; height: 20px; width: 20px; font-size: 10px;">?</button>
                    <button
                        style="position: absolute; top:0% ; right: 0px; height: 20px; width: 20px; font-size: 10px;">X</button>
                </div>
                <div class="card" style="width: 18rem; border-radius: 0px; width: 300px; height: 50px; margin: auto;">
                    <div class="card-body" style="width: 300px; height: 60px; padding: 10px 20px 0px 20px;">
                        <div style="display:flex; flex-direction:row; justify-content: space-between;">
                            <span id="ad-preview-empresa" class="card-title"
                                style="font-size: 1.2rem">{{ad.empresa}}</span>
                            <a href="{{ad.link}}" target="_blank" class="card-link" style="font-size: 1.2rem">Visítanos</a>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
    <!--a class="carousel-control-prev" href="#carousel-tips" role="button" data-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="sr-only">Previous</span>
    </a>
    <a class="carousel-control-next" href="#carousel-tips" role="button" data-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="sr-only">Next</span>
    </a-->
</div>



<!--div id="carouselExampleSlidesOnly" class="carousel slide" data-bs-ride="carousel">
    <div class="carousel-inner">
        <div  class="carousel-item" active style="max-width: 300px;">
            <div  class="mb-3">
                <div style="position: relative; width:300px; margin: auto;">
                    <img id="displayed-image" src="{{url}}/media/ads/{{r_ad.image}}" alt="your image" width="300px"
                        height="200px" />
                    <button
                        style="position: absolute; top:0% ; left: 0px; height: 20px; width: 30px; font-size: 10px; border-radius: 5px;">Ad</button>
                    <button
                        style="position: absolute; top:0% ; right: 20px; height: 20px; width: 20px; font-size: 10px;">?</button>
                    <button
                        style="position: absolute; top:0% ; right: 0px; height: 20px; width: 20px; font-size: 10px;">X</button>
                </div>
                <div class="card" style="width: 18rem; border-radius: 0px; width: 300px; height: 50px; margin: auto;">
                    <div class="card-body" style="width: 300px; height: 60px; padding: 10px 20px 0px 20px;">
                        <div style="display:flex; flex-direction:row; justify-content: space-between;">
                            <span id="ad-preview-empresa" class="card-title"
                                style="font-size: 1.2rem">{{r_ad.empresa}}</span>
                            <a href="{{r_ad.link}}" class="card-link" style="font-size: 1.2rem">Visítanos</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngFor="let myad of ads_vigentes; let i = index" class="carousel-item" style="max-width: 300px;">
            <div class="mb-3">
                <div style="position: relative; width:300px; margin: auto;">
                    <img id="displayed-image" src="{{url}}/media/ads/{{myad.image}}" alt="your image" width="300px"
                        height="200px" />
                    <button
                        style="position: absolute; top:0% ; left: 0px; height: 20px; width: 30px; font-size: 10px; border-radius: 5px;">Ad</button>
                    <button
                        style="position: absolute; top:0% ; right: 20px; height: 20px; width: 20px; font-size: 10px;">?</button>
                    <button
                        style="position: absolute; top:0% ; right: 0px; height: 20px; width: 20px; font-size: 10px;">X</button>
                </div>
                <div class="card" style="width: 18rem; border-radius: 0px; width: 300px; height: 50px; margin: auto;">
                    <div class="card-body" style="width: 300px; height: 60px; padding: 10px 20px 0px 20px;">
                        <div style="display:flex; flex-direction:row; justify-content: space-between;">
                            <span id="ad-preview-empresa" class="card-title"
                                style="font-size: 1.2rem">{{myad.empresa}}</span>
                            <a href="{{myad.link}}" class="card-link" style="font-size: 1.2rem">Visítanos</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div-->