<div class="container-fluid mb-20 mt-10">
  <div class="d-flex justify-content-center">
    <div class="card mb-3" style="max-width: 60%; min-height: 70%;">
      <div class="row no-gutters">
        <div class="col-lg-4">
          <img src="assets/image/citas/fotoCitas.jpeg" style="min-height: 550px; max-height: 600px;" class="card-img"
            alt="...">
        </div>

        <div class="col-lg-8">
          <div class="card-body">
            <h1 class="card-title" style="color: #2C3A4F;">Reserva y haz una cita con Nosotros</h1>
              
            <div class="mb-2">
              <select class="form-control" #consejero_select (change)="onConsejeroChange(consejero_select.value)">
                <option disabled selected [value]="null">Seleccione un asesor</option>
                <option *ngFor="let consejero of consejeros" [value]="consejero.usuario.id">{{consejero.usuario.nombre}}</option>
              </select>
            </div>
            
            <div class="mb-2">
              <select #tema_select class="form-control">
                <option disabled selected [value]="null">Seleccione un tema</option>
                <option *ngFor="let tema of temas" [value]="tema.id">{{tema.nombre}}</option>
              </select>
            </div>

            <div class="mb-2">
              <select #modalidad_select class="form-control">
                <option disabled selected [value]="null">Seleccione una modalidad</option>
                <option value="PRESENCIAL">PRESENCIAL</option>
                <option value="ONLINE">ONLINE</option>
              </select>
            </div>

            <div class="mb-2">
              <input #fecha_input class="form-control" type="date" [min]="min_date" (change)="onDateChange(fecha_input.value)">
            </div>

            <div class="mb-2">
              <select #hora_select class="form-control">
                <option disabled selected [value]="null">Seleccione un horario</option>
                <option *ngFor="let hora of horas" [value]="hora">{{hora}}</option>
              </select>
            </div>

            <div class="form-group mb-2">
              <textarea #motivo_input class="form-control" id="exampleFormControlTextarea1" rows="3" placeholder="Ingrese su motivo"></textarea>
            </div>

            <div class="mb-2">
              <button class="btn btn-primary" (click)="onSubmit(consejero_select.value, tema_select.value, modalidad_select.value, fecha_input.value, hora_select.value, motivo_input.value,accept_modal, success_modal)">
                Registrar asesoría
              </button>
            </div>
            
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #loading>
  <div class="text-center">
    <i class="fas fa-spin fa-spinner fa-5x"></i> <br />
    Loading events...
  </div>
</ng-template>

<ng-template #accept_modal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Mensaje</h4>
    <button type="button" class="btn-close" aria-label="Close" ></button>
  </div>
  <div class="modal-body">
    <p>¿Desea agendar esta asesoría?</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="modal.close('cancel')">Cancelar</button>
    <button type="button" class="btn btn-primary" (click)="modal.close('submit')">Aceptar</button>
  </div>
</ng-template>

<ng-template #success_modal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Mensaje</h4>
    <button type="button" class="btn-close" aria-label="Close" ></button>
  </div>
  <div class="modal-body">
    <p>Su asesoría ha sido agendada exitosamente</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="modal.close()">Aceptar</button>
  </div>
</ng-template>
