<body style="background-color: #666666;">
    <div class="limiter">
        <div class="container-login100">
            <div class="wrap-login100">
                <form class="login100-form validate-form">
                    <span class="login100-form-title p-b-43">
						Inicio de sesión
					</span>


                    <div class="wrap-input100 validate-input" data-validate="Valid email is required: ex@abc.xyz">
                        <input class="input100" type="text" name="username" placeholder="Usuario" [(ngModel)]="username">
                        <span class="focus-input100"></span>
                        <span class="label-input100"></span>
                    </div>


                    <div class="wrap-input100 validate-input" data-validate="Password is required" style="position: relative">
                        <input class="input100" type="password" id="password" name="password" placeholder="Contraseña" [(ngModel)]="password">
                        <div class="mx-2 my-2" style="position: absolute; right: 5%; top: 25%">
                            <span id="hidePass" style="font-size: 1.5rem;;" class="z-10 fa fa-fw fa-eye password-icon show-password" (click)="clickViewPassword()"></span>
                        </div>
                        <span class="focus-input100"></span>
                        <span class="label-input100"></span>
                        
                    </div>

                    

                    <div class="flex-sb-m w-full p-t-3 p-b-32 mt-2">
                        <div class="contact100-form-checkbox">
                            <input class="input-checkbox100" id="ckb1" type="checkbox" name="remember-me">
                            <label class="label-checkbox100" for="ckb1">
								Recordarme
							</label>
                        </div>

                        <div>
                            <a [routerLink]="['/recuperar-contrasenia']" class="txt1">
								olvidaste contrseña?
							</a>
                        </div>
                    </div>


                    <p *ngIf="showErrorMessage"><b>Error en el usuario o contraseña</b></p>

                    <div class="container-login100-form-btn">
                        <button type="submit" class="login100-form-btn" (click)="login()">
							Iniciar sesión
						</button>
                    </div>
                    <hr>
                    <div class="container-login100-form-btn">
                        <button type="submit" class="login100-form-btn" (click)="register()">
							Registrarse
						</button>
                    </div>
                    <div class="text-center p-t-46 p-b-20">
                        <span class="txt2">
							Regresar
						</span>
					</div>
					<!-- <strong>User:</strong> {{user?.getBasicProfile().getEmail() | json}}, {{user?.getBasicProfile().getId() | json}}  -->

                    <div class="login100-form-social flex-c-m">
                        <a href="#" class="login100-form-social-item flex-c-m bg1 m-r-5">
                            <i class="fa fa-chevron-left" aria-hidden="true"></i>
                        </a>
                        <div class="g-signin2" data-onsuccess="onSignIn"></div>


                        <a (click)="authenticate()" class="login100-form-social-item flex-c-m bg2 m-r-5">
                            <i class="fa fa-google" aria-hidden="true"></i>
                        </a>
                        <!-- <a  class="login100-form-social-item flex-c-m bg2 m-r-5">
							<i class="fa fa-facebook" aria-hidden="true"></i>
						</a>  -->
                    </div>

                </form>


                <div class="login100-more" style="background-image: url('../../../assets/image/familia.jpg');">
                </div>
            </div>
        </div>
    </div>
