<footer class="footer-area section_gap">
    <div class="container">
        <div class="row">
            <div class="col-lg-4  col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h6 class="footer_title">Acerca de</h6>
                    <p>Sitio Web creado con la finalidad de poder compartir consejos con la comunidad, que hemos ido adquiriendo gracias nuestro gran recorrido en la vida.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h6 class="footer_title">Navegación</h6>
                    <div class="row">
                        <div class="col-6">
                            <ul class="list_style">
                                <li><a href="#">Inicio</a></li>
                                <li><a href="#">Temas</a></li>
                                <li><a routerLink='/recomendacion' >Tips</a></li>
                                <li><a routerLink='/donacion'>Donaciones</a></li>
                            </ul>
                        </div>
                        <div class="col-6">
                            <ul class="list_style">
                                <li><a routerLink='/galeria'>Galería</a></li>
                                <li><a routerLink='/testimonios' >Testimonios</a></li>
                                <li><a routerLink='/politicas' >Políticas & Privacidad </a></li>
                                <li><a routerLink='/contact'>Contáctanos</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h6 class="footer_title">Escríbenos</h6>
                    <p>Para sugerencias nos pudes escrbir al correo:</p>
                    <div id="mc_embed_signup">
                        <form target="_blank" action="https://spondonit.us12.list-manage.com/subscribe/post?u=1462626880ade1ac87bd9c93a&amp;id=92a4423d01" method="get" class="subscribe_form relative" novalidate="true">
                            <div class="input-group d-flex flex-row">
                                <input name="EMAIL" placeholder="ecmifamilia@gmail.com" disabled onfocus="this.placeholder = ''" onblur="this.placeholder = 'Email Address '" required="" type="email">
                            </div>
                            <div class="mt-10 info"></div>
                        </form>
                    </div>
                </div>
            </div>

        </div>
        <div class="border_line"></div>
        <div class="row footer-bottom d-flex justify-content-between align-items-center">
            <p class="col-lg-8 col-sm-8 footer-text m-0"><!-- Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. -->
Copyright ©{{year}} All rights reserved | This site was made by ESPOL students
<!-- Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. --></p>
            <div class="col-lg-4 col-sm-4 footer-social">
                <a target="_blank" [href]="inforcionContacto.facebook"><i class="fa fa-facebook"></i></a>
                <a target="_blank" [href]="inforcionContacto.twitter"><i class="fa fa-twitter"></i></a>
                <a target="_blank" [href]="inforcionContacto.instagram"><i class="fa fa-instagram"></i></a>
            </div>
        </div>
    </div>
</footer>
