import { formatDate } from '@angular/common';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ProfileInformation } from 'src/app/common/header/services/notificacion';
import { NotificacionesService } from 'src/app/common/header/services/notificaciones.service';
import { environment } from 'src/environments/environment.prod';
import { LoginService } from '../login/login.service';
import { ProfileService } from '../profile/service/profile.service'
declare var $: any;

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})

export class ProfileComponent implements OnInit {
  apiURL: string = environment.apiUrl
  isLogged: boolean = false;
  user_id: number = null;

  usuario: ProfileInformation = null;
  constructor(public router: Router,
    public service_login: LoginService,
    private notificacionesService: NotificacionesService,
    private profileService: ProfileService) { }

  ngOnInit(): void {
    this.getLogginStatus();
    let opcion = localStorage.getItem('profile_option');
    localStorage.setItem('profile_option', undefined);
    if (opcion) {
      if(opcion == 'PROFILE'){
        this.mi_perfil_pressed()
      }else if(opcion == 'SET_INFO'){
        this.editar_info_pressed();
      }else if(opcion == 'SET_FOTO'){
        this.editar_foto_pressed();
      }else if(opcion == 'SET_PASSWORD'){
        this.editar_pass_pressed()
      }
    }else{
      this.mi_perfil_pressed();
    }
    if (this.isLogged) {
      this.notificacionesService.post_profile_information(this.user_id).subscribe((data) => {
        console.log('PROFILE:', data)
        this.usuario = data as ProfileInformation;
      })
    }
  }

  getLogginStatus() {
    let val = localStorage.getItem('isLogged')
    if (val == 'true') {
      this.isLogged = true;
      this.user_id = parseInt(localStorage.getItem('user_id'));
    } else {
      this.isLogged = false;
    }
  }

  public active_button_class = 'btn btn-primary';
  public unactive_button_class = 'btn btn-outline-primary';

  public tab: string = 'perfil';

  mi_perfil_pressed() {
    document.getElementById('perfil_btn').setAttribute('class', this.active_button_class);
    document.getElementById('set_info_btn').setAttribute('class', this.unactive_button_class);
    document.getElementById('set_pass_btn').setAttribute('class', this.unactive_button_class);
    document.getElementById('set_foto_btn').setAttribute('class', this.unactive_button_class);
    this.tab = 'perfil';
  }
  editar_info_pressed() {
    document.getElementById('perfil_btn').setAttribute('class', this.unactive_button_class);
    document.getElementById('set_info_btn').setAttribute('class', this.active_button_class);
    document.getElementById('set_pass_btn').setAttribute('class', this.unactive_button_class);
    document.getElementById('set_foto_btn').setAttribute('class', this.unactive_button_class);
    this.tab = 'info'
  }
  editar_foto_pressed() {
    document.getElementById('perfil_btn').setAttribute('class', this.unactive_button_class);
    document.getElementById('set_info_btn').setAttribute('class', this.unactive_button_class);
    document.getElementById('set_foto_btn').setAttribute('class', this.active_button_class);
    document.getElementById('set_pass_btn').setAttribute('class', this.unactive_button_class);
    this.tab = 'foto';
  }
  editar_pass_pressed() {
    document.getElementById('perfil_btn').setAttribute('class', this.unactive_button_class);
    document.getElementById('set_info_btn').setAttribute('class', this.unactive_button_class);
    document.getElementById('set_foto_btn').setAttribute('class', this.unactive_button_class);
    document.getElementById('set_pass_btn').setAttribute('class', this.active_button_class);
    this.tab = 'pass';
  }

  /********************INFORMATION******************* */

  onSubmit_setInfo(password: string) {
    let apellidos = (document.getElementById('apellidosInput') as HTMLInputElement).value
    let nombres = (document.getElementById('nombresInput') as HTMLInputElement).value
    let nacimiento = (document.getElementById('nacimientoInput') as HTMLInputElement).value
    let cedula = (document.getElementById('cedulaInput') as HTMLInputElement).value
    let telefono = (document.getElementById('telefonoInput') as HTMLInputElement).value

    if (apellidos == '' &&
      nombres == '' &&
      nacimiento == this.usuario.nacimiento &&
      cedula == '' &&
      telefono == '') {
      alert('Debe hacer al menos un cambio para poder actualizar su información');
      return;
    }
    if (password == undefined || password == '') {
      alert('Debe ingresar la contraseña para poder actualizar su información');
      return;
    }
    if (nacimiento == this.usuario.nacimiento) {
      nacimiento = '';
    }
    let req = {
      "username": this.usuario.username,
      "password": password,
      "nombres": nombres,
      "apellidos": apellidos,
      "nacimiento": nacimiento,
      "cedula": cedula,
      "telefono": telefono
    }
    console.log(req)
    this.profileService.post_update_info(req).subscribe((data) => {
      if (data['status']) {
        alert('Sus información ha sido actualizada con éxito.')
        window.location.reload();
      } else {
        alert(data['message'])
      }
    })

  }


  /**********************PASSWORD********************* */

  onSubmit_setPassword(password: string, new_password: string, repeat_password: string) {
    if (new_password != repeat_password) {
      alert('La contraseña no coincide');
      return
    }
    this.profileService.post_update_password(this.usuario.username, password, new_password).subscribe((data) => {
      console.log(this.usuario.username, password, new_password)
      if (data['status']) {
        alert('La contraseña fue actualizada con éxito!');
        window.location.reload();
      } else {
        alert(data['message']);
      }
    })
  }


  /**********************FOTO*************************** */


  public static image_height: number;
  public static image_width: number;


  static readURL(input) {
    if (input.files && input.files[0]) {
      var reader = new FileReader();
      reader.onload = function (e) {
        document.getElementById('preview-foto').setAttribute('src', <string>e.target.result)
      };
      reader.readAsDataURL(input.files[0]);
    }
  }

  onImageChange() {
    let input = document.getElementById('profile-img-picker') as HTMLInputElement;
    let files = input.files;
    var _URL = window.URL || window.webkitURL;
    var file, img;
    if ((file = files[0])) {
      img = new Image();
      var objectUrl = _URL.createObjectURL(file);
      img.onload = function () {
        //alert(this.width + " " + this.height);
        ProfileComponent.image_height = this.height;
        ProfileComponent.image_width = this.width;
        if (ProfileComponent.image_height != ProfileComponent.image_width) {
          alert('La imagen debe ser cuadrada');
        } else {
          ProfileComponent.readURL(input)
        }
        _URL.revokeObjectURL(objectUrl);
      };
      img.src = objectUrl;
    }
  }

  async onUploadProfileImage() {
    let image_path = (document.getElementById('profile-img-picker') as HTMLInputElement).value
    if (image_path == '' || image_path == undefined) {
      alert('Seleccione una imagen')
    }
    let img_name = image_path.replace("C:\\fakepath\\", '')
    let img_data;
    let postBody = {
      "username": this.usuario.username,
      "password": (document.getElementById('foto-form-password') as HTMLInputElement).value,
      "image": img_name,
      files: { "file": img_data }
    }

    var reader = new FileReader();
    let input = document.getElementById('profile-img-picker') as HTMLInputElement;
    if (input.files && input.files[0]) {
      console.log(ProfileComponent.image_height, ProfileComponent.image_width);
      if (ProfileComponent.image_height != ProfileComponent.image_width) {
        alert('La imagen de perfil debe ser cuadrada');
        return;
      }
      var reader = new FileReader();

      reader.readAsDataURL(input.files[0]);
      reader.onload = async function (e) {
        console.log(postBody)
        img_data = e.target.result;
        postBody.files['file'] = e.target.result
        fetch(`${environment.apiUrl}/api/post_update_foto_perfil/`, {
          method: 'POST',
          headers: {},
          body: JSON.stringify(postBody)
        })
          .then(response => response.json())
          .then(data => {
            if (data.status) {
              //TODO: ALERT SUCCESS
              alert('La imgaen de perfil fue actualizada con éxito!')
              location.reload();

              //console.log('FOTO ACTUALIZADA CORRECTAMENTE', data['profile_img'])
              //$('#ad-registro-exitoso-modal').modal({ show: true, backdrop: "static" })
            } else {
              alert(data['message']);
              //$('#ad-registro-fallido-modal').modal({ show: true, backdrop: "static" })
            }
          })
      };
    }
  }




}
