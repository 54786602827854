import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment.prod';
import { Observable } from 'rxjs';
import { InformacionContactato } from 'src/app/components/index/interfaces';

@Injectable({
  providedIn: 'root'
})
export class NotificacionesService {
  public apiURL = environment.apiUrl;

  constructor(private http: HttpClient) { }

  get_notificaciones(user_id: number){
    let id = new Number(user_id);
    return this.http.get(this.apiURL+'/api/getNotificaciones/'+id.toString());
  }

  post_checkNotificacion(user_id: number, notificacion_id: number){
    return this.http.post(this.apiURL+'/api/postCheckNotificacion', {'user_id': user_id, 'notificacion_id': notificacion_id });//'+);
  }

  post_profile_information(user_id){
    return this.http.post(this.apiURL+'/api/post_profile_information/',{'user_id': user_id});
  }

  getInfomacionContacto(): Observable<InformacionContactato>{
    return this.http.get<InformacionContactato>(this.apiURL+'/api/getInformacionContacto/')
  }
}
