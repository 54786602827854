import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Evento } from './evento'

@Injectable({
  providedIn: 'root'
})
export class EventoService {
  public apiURL = environment.apiUrl;
  public imgURL = environment.imgUrl;

  constructor(private http: HttpClient) {

  }

  get_eventos(){
    return this.http.get<Evento[]>(`${this.apiURL}/api/get_eventos`)
  }


}
