
         <!--================Header Area =================-->
         <header class="header_area"></header>
         <!--================Header Area =================-->
        <!--================Breadcrumb Area =================-->
        <section class="breadcrumb_area br_image">
            <div class="container">
                <div class="page-cover text-center">
                    <h2 class="page-cover-tittle">Contáctanos</h2>
                    <ol class="breadcrumb">
                        <li><a href="index.html">Inicio</a></li>
                        <li class="active">Contáctanos</li>
                    </ol>
                </div>
            </div>
        </section>
        <!--================Breadcrumb Area =================-->

        <!--================Contact Area =================-->
        <section class="contact_area section_gap">
            <div class="container">
                <div class="row">
                    <div class="col-md-3">
                        <div class="contact_info">
                            <div class="info_item">
                                <i class="lnr lnr-home"></i>
                                <h6>Guayas, Ecuador</h6>
                                <p>{{direccion}}</p>
                            </div>
                            <div class="info_item">
                                <i class="lnr lnr-phone-handset"></i>
                                <h6><a href="#">{{telefono}}</a></h6>
                                <p>Lunes a Viernes</p>
                            </div>
                            <div class="info_item">
                                <i class="lnr lnr-envelope"></i>
                                <h6><a href="#">{{correo}}</a></h6>
                                <p>Comunícate en cualquier momento!</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-9">
                        <form [formGroup]="registerForm" (ngSubmit)="onSubmit()"   class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <input type="text" class="form-control"  formControlName="name" placeholder="Ingresa tus nombres" [ngClass]="{ 'is-invalid': submitted && f.name.errors }" />
                                    <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                                        <div *ngIf="f.name.errors.required">Los Nombres son requeridos</div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <input type="text" formControlName="email" placeholder="Ingresa tu email" class=" form-control" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                                    <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                        <div *ngIf="f.email.errors.required">Email es requerido</div>
                                        <div *ngIf="f.email.errors.email">Email debe de ser una dirección valida</div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <input type="text" class="form-control" formControlName="title" placeholder="Ingresa el asunto" [ngClass]="{ 'is-invalid': submitted && f.title.errors }" />
                                    <div *ngIf="submitted && f.title.errors" class="invalid-feedback">
                                        <div *ngIf="f.title.errors.required">El asunto es requerido</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                </div>
                                <textarea type="text" class="form-control" formControlName="description" rows="4" placeholder="Danos tu opinión..." [ngClass]="{ 'is-invalid': submitted && f.description.errors }" ></textarea>
                                <div *ngIf="submitted && f.description.errors" class="invalid-feedback">
                                    <div *ngIf="f.description.errors.required">Tu opinión es requerida</div>
                                    <div *ngIf="f.description.errors.minlength">Debes ingresar mínimo 10 caracateres</div>
                                </div>
                            </div>
                            <div class="col-md-12 text-right">
                                <button type="submit" value="submit" (click)="set()" class="btn btn_hover btn_hover_two">Enviar</button>
                            </div>
                        </form>
                        <div *ngIf="showMessage" class="alert alert-success alert-dismissible fade show" role="alert">
                            <strong>Enviado!</strong> Mensaje enviado correctamente.
                            <!-- <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                              <span aria-hidden="true">&times;</span>
                            </button> -->
                          </div>
                    </div>
                </div>
            </div>
        </section>
        <!--================Contact Area =================-->

