
  <!--================Header Area =================-->
  <header class="header_area"></header>
  <!--================Header Area =================-->
  <!--================Breadcrumb Area =================-->
 <section class="breadcrumb_area br_image " style="margin-top: 7rem !important;">
    <div class="container">
        <div class="page-cover text-center">
            <h2 class="page-cover-tittle">Asesorías</h2>
            <ol class="breadcrumb">
                <li><a href="index.html">Inicio</a></li>
                <li class="active">Asesorías</li>

            </ol>
        </div>
    </div>
</section>
<ng-template #loading>
  <div class="text-center">
    <i class="fas fa-spin fa-spinner fa-5x"></i> <br />
    Loading events...
  </div>
</ng-template>

<div class="d-flex flex-row justify-content-around mt-10">
  <div class="nav-item mt-10">
    <button class="btn btn-primary" (click)="handleClickHistorial()">Historial citas</button>
  </div>
  <div class="nav-item mt-10">
    <button class="btn btn-primary" (click)="handleClicKAgendar()">Agendar Cita</button>
  </div>
</div>

<ng-template #HistorialCItas [ngIf]="vistaTemplate === 'HISTORIAL'">
  <app-historial-citas [visitaTemplate]="vistaTemplate"></app-historial-citas>
</ng-template>

<ng-template #AgendarCitas [ngIf]="vistaTemplate === 'AGENDAR'" >
  <app-agendar-citas></app-agendar-citas>
</ng-template>

