import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Imagen, Videos } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class GaleriaService {
  private url: string = environment.apiUrl;

  constructor(private _http: HttpClient) { }


  get_galeriaImages(): Observable<Imagen[]> {
    return this._http.get<Imagen[]>(this.url+"/api/images_galeria/");
  }

  get_galeriaVideos(): Observable<Videos[]> {
    return  this._http.get<Videos[]>(this.url+"/api/videos_galeria/")
  }
}
