import { Component, OnInit, Input } from '@angular/core';
import { Consejeria, ConsejeriasAux } from './interfaces';
import { HistorialCitasService } from './service/historial-citas.service';
import { NgbDate, NgbDateStruct, NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { LoginService } from '../login/login.service';
import { Observable, of } from 'rxjs';
import { TemaConsejeria } from '../agendar.citas/interfaces';
import { AgendarService } from '../agendar.citas/service/agendar.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
@Component({
  selector: 'app-historial-citas',
  templateUrl: './historial-citas.component.html',
  styleUrls: ['./historial-citas.component.css']
})
export class HistorialCitasComponent implements OnInit {


   /** form data */
   formConsejeria: FormGroup = new FormGroup({
    optionSelectTemaConsejeria: new FormControl(null, [Validators.required]),
    optionSelectModalidad: new FormControl('', [Validators.required]),
    optionMotivo: new FormControl('', [Validators.required, Validators.maxLength(1000)]),
    optiondate: new FormControl({ value: {} }, [Validators.required]),
    optionSelectHour: new FormControl(null, [Validators.required])
  })

  /** params of consejerias of teh backend */
  consejeriasPendientes: Observable<Consejeria[]>;
  consejeriasTerminadas: Observable<Consejeria[]>;
  user_id: number

  @Input() vistaTemplate: string

  /** params of the tables */
  page1: number = 1;
	pageSize1: number = 4;

  page2: number = 1;
	pageSize2: number = 4;

	consejeriasPendientesSize: number = 0;
  consejeriasTerminadasSize: number = 0;

  consejeriasAuxPendientes: Consejeria[] = [];
  consejeriasAuxTerminadas: Consejeria[] = [];

  /** Data for template */
  consejerias: ConsejeriasAux[];
  modalidad: string[] = [
    "PRESENCIAL",
    "ONLINE"
  ]
  temaConsjeria: TemaConsejeria[]
  hours = [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23]

  /** modal confirmation */
  mensaje: string = ''
  descripcionMensaje: string = ''

  constructor(
    private historialServices: HistorialCitasService,
    private loginServices: LoginService,
    private modalService: NgbModal,
    private consejeriasServices: AgendarService
  ) {}


   async ngOnInit() {
        this.user_id = this.loginServices.getUserId();
        this.consejeriasPendientes = this.historialServices.get_consejeria_usuario_pendientes(this.user_id)
        this.consejeriasTerminadas = this.historialServices.get_consejeria_usuario_terminadas(this.user_id)
        this.historialServices.get_consejeria_usuario_pendientes(this.user_id).subscribe(data => {
          console.log(data)
          console.log('done', this.consejeriasPendientes);
          this.consejeriasPendientesSize =  data.length
          this.refreshPendientes()
        })
        this.historialServices.get_consejeria_usuario_terminadas(this.user_id).subscribe(data => {
          console.log('done', this.consejeriasTerminadas);
          this.consejeriasTerminadasSize = data.length
          this.refreshTerminadas()
        })
        this.consejeriasServices.get_tema_consejeria().subscribe(data => {
          this.temaConsjeria = data
        })
        this.consejeriasServices.get_consejerias().subscribe(data => {
          this.consejerias = data
        })
      }

  refreshPendientes() {
		/*this.consejeriasAuxPendientes = this.consejeriasPendientes.slice(
			(this.page1 - 1) * this.pageSize1,
			(this.page1 - 1) * this.pageSize1 + this.pageSize1,
		);*/
    /*let body = document.getElementById('table-body-pendientes') as HTMLElement
    body.innerHTML = '';
    for(let consejeria of this.consejeriasAuxPendientes){
      let p = `
            <tr>
              <td>${consejeria.consejero.nombre}</td>
              <td>${consejeria.tema.nombre}</td>
              <td>${consejeria.modalidad}</td>
              <td>${(consejeria.direccion == "") ? consejeria.link : consejeria.direccion}</td>
              <td>${consejeria.fecha}</td>
              <td>${consejeria.hora}</td>
              <td>
                <button
                  class='btn btn-primary'
                  onclick="handleClickEditar(${consejeria.id})"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width:"16" height="16" fill="currentColor" class="bi bi-pen-fill" viewBox="0 0 16 16">
                    <path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001z"/>
                  </svg>
                </button>
                <button class='btn btn-danger'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                    <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                  </svg>
                </button>
              </td>
            </tr>
            `
      body.innerHTML += p;
    }*/
	}

  refreshTerminadas() {
		/*this.consejeriasAuxTerminadas = this.consejeriasTerminadas.slice(
			(this.page2 - 1) * this.pageSize2,
			(this.page2 - 1) * this.pageSize2 + this.pageSize2,
		);
    let body = document.getElementById('table-body-terminadas') as HTMLElement
    body.innerHTML = '';
    for(let consejeria of this.consejeriasAuxTerminadas){
      let p = `<tr><td>${consejeria.consejero.nombre}</td>
            <td>${consejeria.tema.nombre}</td>
            <td>${consejeria.modalidad}</td>
            <td>${(consejeria.direccion == "") ? consejeria.link : consejeria.direccion}</td>
            <td>${consejeria.fecha}</td>
            <td>${consejeria.hora}</td></tr>`
      body.innerHTML += p;
    }*/
	}

  handleClickEliminar(idConsejeria: number, contentmensaje) {
    this.descripcionMensaje = 'Desea eliminar su consejeria'
    this.mensaje = 'Eliminar'
    this.modalService.open(contentmensaje, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.historialServices.eliminar_consejeria_usuario(idConsejeria).subscribe(data => {
        this.descripcionMensaje = data
        this.modalService.open(contentmensaje, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
          window.location.reload()
        })
      })
    })
  }


  consejeria_editar = undefined;
  handleClickEditar(consejeria: Consejeria, contentFormularioEditar,contentmensaje) {
    /* console.log(consejeria)
    this.consejerias = this.consejerias.filter(
      (conj: Consejeria) => conj.consejero.id === consejeria.consejero.id
    )
    this.formConsejeria.setValue({
      optionSelectTemaConsejeria: consejeria.tema.id,
      optionSelectModalidad: consejeria.modalidad,
      optionMotivo: consejeria.motivo,
      optiondate: consejeria.fecha,
      optionSelectHour: consejeria.hora
    })
    this.modalService.open(contentFormularioEditar, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.descripcionMensaje = 'Desea guardar los cambios'
      this.mensaje = 'Editar'
      const temaConsejeria: TemaConsejeria = this.formConsejeria.get('optionSelectTemaConsejeria').value
      const modalidadConsejeria: string = this.formConsejeria.get('optionSelectModalidad').value
      const motivoConsejeria: string = this.formConsejeria.get('optionMotivo').value
      const date: NgbDate = this.formConsejeria.get('optiondate').value
      const hours: number = this.formConsejeria.get('optionSelectHour').value

      if (
        temaConsejeria !== null &&
        hours !== null &&
        modalidadConsejeria !== '' &&
        motivoConsejeria !== ''
      ) {
        let hourAux = ''
        hourAux = hours + ':00'
        if (hours < 10) {
          hourAux = '0' + hours + ':00'
        }
        console.log(hourAux)
        let consejeriaPost = {
          conjid: consejeria.id,
          tema: temaConsejeria,
          modalidad: modalidadConsejeria,
          link: "",
          direccion:  "",
          fecha: date.year + '-' + date.month + '-' + date.day,
          hora: hourAux,
          precio: 50.50,
          motivo: motivoConsejeria
        }
        if(modalidadConsejeria === 'ONLINE'){
          consejeriaPost.link = "https://www.google.com"
        }
        if(modalidadConsejeria === 'PRESENCIAL'){
          consejeriaPost.direccion = 'Direccion 123'
        }
        //this.modalService.dismissAll();
        this.modalService.open(contentmensaje, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
          this.historialServices.editar_consejeria(consejeriaPost).subscribe(data => {
            this.descripcionMensaje = 'Consejeria actualizada correctamente'
            this.mensaje = 'Editar'
            this.modalService.open(contentmensaje, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
              window.location.reload()
            })
          })
        })
      }
    }) */
    this.consejeria_editar = consejeria;
    this.modalService.open(contentFormularioEditar, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {

    });
  }

  onDateSelect(event : NgbDate){
    if (this.consejerias.length === 0) {
      console.log('no hay ')
      return
    }

    const date = event.year + '-' + event.month + '-' + event.day

    this

    let horasNoDisponibles = this.consejerias.map( (consejeria) => {
      if (consejeria.fecha === date) {
        return Number(consejeria.hora.split(':')[0])
      }
    })

    horasNoDisponibles = horasNoDisponibles.filter( hora => hora !== undefined)

    this.hours = this.hours.filter( hora => !horasNoDisponibles.includes(hora))
  }


  isDisabled(event: NgbDate): boolean {
    const date = new Date()
    const yearNow = date.getFullYear()
    const monthNow = date.getMonth() + 1
    const dayNow = date.getDate()

    console.log( yearNow, monthNow, dayNow)
    const date1 = new Date(yearNow , monthNow, dayNow)
    const date2 = new Date(event.year, event.month, event.day)

    if (date2 < date1){
      return true
    }
    return false
  }

}




