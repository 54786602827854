import { HttpClient, HttpEvent, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment.prod';
import { Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  private url  = environment.apiUrl;

  constructor(private http: HttpClient,) { }

  post_update_password(username: string, password: string, new_password: string){
    return this.http.post(this.url+'/api/post_update_password/', {'username': username, 'password': password, 'new_password': new_password});
  }

  post_update_info(data: any){
    return this.http.post(this.url+'/api/post_update_info/',data);
  }

}
