import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment.prod';
import { Consejeria, EmpleadoRol, TemaConsejeria } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class AgendarService {
  private url  = environment.apiUrl;
  isAgendado:boolean=false;
  constructor(private http: HttpClient) { }

  post_agendar_consejerias(form: any): Observable<any> {
    return this.http.post(this.url+"/api/agendar_consejeria/", form);
  }

  get_consejerias(): Observable<Consejeria[]> {
    return this.http.get<Consejeria[]>(this.url+"/api/get_consejerias/")
  }

  get_empleados_rol(): Observable<EmpleadoRol[]> {
    return this.http.get<EmpleadoRol[]>(this.url+"/api/get_empleados_rol/")
  }

  get_tema_consejeria(): Observable<TemaConsejeria[]> {
    return this.http.get<TemaConsejeria[]>(this.url+"/api/get_tema_consejeria/")
  }

  getHorasDisponiblesConsejeroFecha(consejero_id: number, fecha: string){
    return this.http.get(this.url+'/api/get_horas_disponibles_consejero_fecha/'+ consejero_id +'/'+ fecha);
  }

  getConsejerosDisponibles(){
    return this.http.get(this.url+'/api/get_consejeros_disponibles/');
  }


}
