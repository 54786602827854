<!--==================baners==========================-->
<section class="banner-section" style="display: flexbox; justify-content: center">
    <div class="carousel-div" style="display: block; max-width: 1920px; margin: auto">
        <div id="carousel-banner" class="carousel slide" data-ride="carousel">
            <ol class="carousel-indicators">
                <li data-target="#carousel-banner" data-slide-to="0" class="active"></li>
                <li data-target="#carousel-banner" data-slide-to="1"></li>
                <li data-target="#carousel-banner" data-slide-to="2"></li>
                <li data-target="#carousel-banner" data-slide-to="0"></li>
            </ol>
            <div class="carousel-inner">
                <div class="carousel-item active">
                    <div class="banner1-text-div">
                        <h1 id="banner1-h1" style="text-align: center; color: #283b4a">
                            EDUCACIÓN
                        </h1>
                        <h2 id="banner1-h2" style="text-align: center; color: #283b4a">
                            DE HIJOS!
                        </h2>
                        <div style="display: flex; align-content: center">
                            <a href="#/tema-details/83/2" style="align-self: center; margin: auto; display: block">
                                <button class="btn btn-primary">Más información</button>
                            </a>
                        </div>
                    </div>
                    <img class="d-block w-100" src="/assets/banners/banner1.jpg" alt="First slide" />
                </div>
                <div class="carousel-item">
                    <div class="banner2-text-div">
                        <h1 id="banner2-h1" style="text-align: center; color: #283b4a">
                            DEFENDEREMOS LA VIDA
                        </h1>
                        <div style="display: flex; align-content: center">
                            <a href="#/tema-details/81/3" style="align-self: center; margin: auto; display: block">
                                <button class="btn btn-primary">Más información</button>
                            </a>
                        </div>
                    </div>
                    <img class="d-block w-100" src="/assets/banners/banner2.jpg" alt="Second slide" />
                </div>
                <div class="carousel-item">
                    <div class="banner3-text-div">
                        <h1 id="banner3-h1" style="text-align: center; color: #283b4a">
                            ALIMENTACIÓN Y NUTRICIÓN!
                        </h1>
                        <div style="display: flex; align-content: center">
                            <a href="#/tema-details/82/4" style="align-self: center; margin: auto; display: block">
                                <button class="btn btn-primary">Más información</button>
                            </a>
                        </div>
                    </div>
                    <img class="d-block w-100" src="/assets/banners/banner3.jpg" alt="Third slide" />
                </div>
                <div class="carousel-item">
                    <div class="banner4-text-div">
                        <h1 id="banner4-h1" style="text-align: center; color: #ffffff;">
                            CÓMO MEJORAR LAS RELACIONES ENTRE ESPOSOS
                        </h1>
                        <div style="display: flex; align-content: center">
                            <a *ngIf="_temas.length != 0"
                                href="#/tema-details/{{_temas[0].id}}/{{_temas[0].id_categoria}}"
                                style="align-self: center; margin: auto; display: block">
                                <button class="btn btn-primary">Más información</button>
                            </a>
                        </div>
                    </div>
                    <img class="d-block w-100" src="/assets/banners/banner4.jpg" alt="Third slide" />
                </div>
            </div>
            <a class="carousel-control-prev" href="#carousel-banner" role="button" data-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="sr-only">Previous</span>
            </a>
            <a class="carousel-control-next" href="#carousel-banner" role="button" data-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="sr-only">Next</span>
            </a>
        </div>
    </div>
</section>
<!--================banner Area =================-->


<!--================Noticias Area =================-->
<section class="topics-section background-gray pb-5">
    <div *ngIf="_temas?.length" class="container-div">
        <div class="flex-div"
            style="flex-direction: column; margin-bottom: 3rem; position: relative; max-height: 175px;">
            <div style="display: block; margin: auto">
                <h1 class="block" id="galeria-shadow" style="color: #e8ebea;">NOTICIAS</h1>
            </div>
            <div class="section-subtitle" style="display: block; position:absolute; top: 25%; left: 50%;">
                <h2 class="block index-title" id="galeria-subtitle" style=" color: #2d9ffd; text-align: center;">
                    TIPS RECOMENDACIONES Y
                </h2>
            </div>
            <div class="section-title" style="display: block; position:absolute; top: 60%;">
                <h1 class="block index-subtitle" id="galeria-title" style=" color: #283b4a;text-align: center;">
                    NOTICIAS</h1>
            </div>
            <div class="underline-div" style="top:90%; max-width: 20rem;"></div>
        </div>

        <div class="flex-div" style="margin-top: 2rem;">
            <div class="block-div" *ngFor="let tema of _temas; let i = index;" style="margin-top: 15px;">
                <app-tema-card [tema]="tema"></app-tema-card>
            </div>
        </div>
    </div>
</section>
<!--================Noticias Area =================-->

<!--==================baners publicidad==========================-->
<section class="container background-gray px-0 mb-4"
    style="margin: auto; display: flexbox; max-width: 100% ; max-height: 76%; justify-content: center; text-align: center;">
    <div class="carousel-div " style="display: block; max-width: 1920px; margin: auto">
        <div id="carousel-ads" class="carousel slide" data-ride="carousel">
            <div class="carousel-inner" style="min-height: 10rem !important; ">
                <div class="carousel-item" *ngFor="let ad of publicidades;let index = index;let isFirst = first"
                    [ngClass]="{active:isFirst}">
                    <img [src]="adsURL+ad.image" class="d-block w-100 py-2 ad-div" [alt]="" (click)="onClickAd(ad)">
                </div>
            </div>
            <a class="carousel-control-prev" href="#carousel-ads" role="button" data-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="sr-only">Previous</span>
            </a>
            <a class="carousel-control-next" href="#carousel-ads" role="button" data-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="sr-only">Next</span>
            </a>
        </div>
    </div>
</section>
<!--================banner publicidd Area =================-->


<!--================Galeria Area================-->
<section class="topics-section background-gray mt-1" style="padding-bottom: 4rem;">
    <div *ngIf="_temas?.length" class="container-div">
        <div class="flex-div"
            style="flex-direction: column; margin-bottom: 3rem; position: relative; max-height: 175px;">
            <div style="display: block; margin: auto">
                <h1 class="block" id="galeria-shadow" style="color: #e8ebea;">GALERÍA</h1>
            </div>
            <div class="section-subtitle" style="display: block; position:absolute; top: 25%; left: 50%;">
                <h2 class="block index-title" id="galeria-subtitle" style=" color: #2d9ffd; text-align: center;">
                    NUESTRAS FOTOGRAFÍAS
                </h2>
            </div>
            <div class="section-title" style="display: block; position:absolute; top: 60%;">
                <a href="#/galeria">
                    <h1 class="block index-subtitle" id="galeria-title" style=" color: #283b4a;text-align: center;">
                        GALERÍA</h1>
                </a>
            </div>
            <div class="underline-div" style="top:90%; max-width: 20rem;"></div>
        </div>

        <div id="galeria-div">
            <div *ngFor="let imagen of _imagenes; let i = index;" class="img-container" id="galeria-{{i+1}}-photo-div">
                <img *ngIf="i==0" class="image" src="{{url}}/media/{{_imagenes[0]['image']}}">
                <div *ngIf="i==0" class="middle">
                    <button (click)="onClickImage(content,_imagenes[0])" class="btn btn-primary">Ver</button>
                </div>

                <img *ngIf="i==1" class="image" src="{{url}}/media/{{_imagenes[1]['image']}}">
                <div *ngIf="i==1" class="middle">
                    <button (click)="onClickImage(content,_imagenes[1])" class="text btn btn-primary">Ver</button>
                </div>

                <img *ngIf="i==2" class="image" src="{{url}}/media/{{_imagenes[2]['image']}}">
                <div *ngIf="i==2" class="middle">
                    <button (click)="onClickImage(content,_imagenes[2])" class="text btn btn-primary">Ver</button>
                </div>

                <img *ngIf="i==3" class="image" src="{{url}}/media/{{_imagenes[3]['image']}}">
                <div *ngIf="i==3" class="middle">
                    <button (click)="onClickImage(content,_imagenes[3])" class="text btn btn-primary">Ver</button>
                </div>

                <img *ngIf="i==4" class="image" src="{{url}}/media/{{_imagenes[4]['image']}}">
                <div *ngIf="i==4" class="middle">
                    <button (click)="onClickImage(content,_imagenes[4])" class="text btn btn-primary">Ver</button>
                </div>
            </div>

        </div>
    </div>
</section>

<!--================Galeria Area================-->


<!--================Tips Area =================-->
<section class="event_date_area tips-area">
    <div class="">
        <div class="carousel-div pt-4" style="display: block; margin: auto">
            <div id="carousel-tips" class="carousel slide" data-ride="carousel">
                <div class="carousel-inner" style="min-height: 130px;">
                    <div class="carousel-item active">
                        <div class="flex-div" style="flex-direction: column; justify-content: center;">
                            <h2 class="tips-h2">Cuanto más trabajo, más suerte parezco tener.</h2>
                            <h3 class="tips-h4">(Thomas Jefferson)</h3>
                        </div>
                    </div>
                    <div class="carousel-item">
                        <div class="flex-div" style="flex-direction: column; justify-content: center;">
                            <h2 class="tips-h2">Estudia el pasado si quieres intuir el futuro.</h2>
                            <h3 class="tips-h4">(Confusio)</h3>
                        </div>
                    </div>
                    <div class="carousel-item">
                        <div class="flex-div" style="flex-direction: column; justify-content: center;">
                            <h2 class="tips-h2">La corona del anciano son sus nietos; el orgullo de los hijos son sus
                                padres.</h2>
                            <h3 class="tips-h4">(Proverbios 17)</h3>
                        </div>
                    </div>
                    <div class="carousel-item">
                        <div class="flex-div" style="flex-direction: column; justify-content: center;">
                            <h2 class="tips-h2">El hijo sabio es la alegría de su padre; el hijo necio es el pesar de su
                                madre.</h2>
                            <h3 class="tips-h4">(Proverbios 10)</h3>
                        </div>
                    </div>
                </div>
                <a class="carousel-control-prev" href="#carousel-tips" role="button" data-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="sr-only">Previous</span>
                </a>
                <a class="carousel-control-next" href="#carousel-tips" role="button" data-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="sr-only">Next</span>
                </a>
            </div>
        </div>
    </div>
</section>
<!--================Tips Area =================-->

<!--================Reserva Area =================-->
<section class="about_area">
    <!--div class="reserva-card container" style="padding: 0px; margin-top: 2rem;">
        <div id="reservas-div" class="flex-div container;">
            <div id="reserva-img-div" class="block-div-np">
                <div id="reserva-img-inner-div" style="min-width: 35rem;">
                    <img id="reserva-img" src="assets/banners/consj-foto.jpg">
                </div>
            </div>
            <div id="reserva-title-div" class="block-div-np">
                <div class="flex-div mt-5">
                    <h2 id="reserva-title" class="block-div-np" style="color: #082a45; margin-bottom: 0;">Reserva y haz
                        una
                        cita con nosotros</h2>
                    <div style="height:fit-content; width: 100%;">
                        <div class="underline" style="max-width: 20rem;"></div>
                    </div>
                    <div style="min-height: 8rem;"></div>

                    <a *ngIf="!isLogged" id="reserva-btn" class="btn btn-primary btn-lg" routerLink="/login">Iniciar
                        Sesión</a>

                    <a *ngIf="isLogged" id="reserva-btn" class="btn btn-primary btn-lg"
                        routerLink="/calendar">Reservar</a>
                    <span class="block mt-3" *ngIf="!isLogged"
                        style="width: 100%; margin: auto;text-align: center; font-size: 1.2rem;">Es necesario iniciar
                        sesión para agendar consejerías.</span>
                </div>
            </div>
        </div>
    </div-->
    <div class="reserva-card container mt-5 " style="padding-left: 0px; padding-right: 0%;">

        <div class="d-flex flex-row flex-wrap justify-content-between">
            <div id="reserva-img-div">
                <div class="d-flex">
                    <div style="margin: auto;">
                        <img src="assets/banners/consj-foto.jpg">
                    </div>
                </div>
            </div>

            <div id="reserva-title-div" class="block-div-np mx-3 my-4 px-2 py-2 border border-dark">
                <div class="flex-div">
                    <h2 id="reserva-title" class="block-div-np "
                        style="color: #082a45; margin-bottom: 0;">Reserva y haz
                        una
                        cita con nosotros</h2>
                    <div style="height:fit-content; width: 100%;">
                        <div class="underline" style="max-width: 20rem;"></div>
                    </div>
                    <div style="min-height: 8rem;"></div>
                    <!--button id="reserva-btn" class="btn btn-primary btn-lg mt-5">Inicia sesión y reserva aquí</button-->

                    <a *ngIf="!isLogged" id="reserva-btn" class="btn btn-primary btn-lg" routerLink="/login">Iniciar
                        Sesión</a>

                    <a *ngIf="isLogged" id="reserva-btn" class="btn btn-primary btn-lg"
                        routerLink="/calendar">Reservar</a>
                    <span class="block mt-3" *ngIf="!isLogged"
                        style="width: 100%; margin: auto;text-align: center; font-size: 1.2rem;">Es necesario iniciar
                        sesión para agendar asesorías.</span>
                </div>
            </div>
        </div>
    </div>


    <div class="container">
        <div class="flex-div"
            style="flex-direction: column; margin-bottom: 3rem; position: relative; max-height: 175px;">
            <div style="display: block; margin: auto">
                <h1 class="block" id="galeria-shadow" style="color:transparent;">T</h1>
            </div>
            <div class="section-subtitle" style="display: block; position:absolute; top: 25%; left: 50%;">
                <h2 class="block index-title" id="galeria-subtitle" style=" color: #2d9ffd; text-align: center;">
                    TESTIMONIOS DE NUESTROS PACIENTES
                </h2>
            </div>
            <div class="section-title" style="display: block; position:absolute; top: 60%;">
                <h1 class="block index-subtitle" id="galeria-title" style=" color: #283b4a;text-align: center;">
                    TESTIMONIOS</h1>
            </div>
            <div class="underline-div" style="top:90%; max-width: 20rem;"></div>
        </div>
    </div>
</section>
<!--================Reserva Area =================-->

<!--================Reviews Area =================-->
<section class="reviews-section">
    <div class="container">
        <div class="carousel-div pt-3" style="display: block; margin: auto">
            <div id="carousel-review" class="carousel slide" data-ride="carousel">
                <div class="carousel-inner" style="min-height: 130px;">
                    <div class="carousel-item active">
                        <div style="display: flex; justify-content: center; align-content:space-around">
                            <div clas="block-div">
                                <i class="bi bi-person-circle fa-9x" style="color:white"></i>
                            </div>
                            <div class="block-div">
                                <div class="flex-div" style="flex-direction: column; justify-content: start;">
                                    <i class="bi bi-quote fa-4x quote-icon"></i>
                                    <h2 class="review-h2">"Muy buen servicio, en solo dos sesiones me ayudaron a superar
                                        mis
                                        problemas."</h2>
                                    <h3 class="review-h4">ANDREA TOMALÁ</h3>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="carousel-item">
                        <div style="display: flex; justify-content: center; align-content:space-around">
                            <div clas="block-div">
                                <i class="bi bi-person-circle fa-9x" style="color:white"></i>
                            </div>
                            <div class="block-div">
                                <div class="flex-div" style="flex-direction: column; justify-content: start;">
                                    <i class="bi bi-quote fa-4x quote-icon"></i>
                                    <h2 class="review-h2">"Una excelente opción para resolver problemas con la familia."
                                    </h2>
                                    <h3 class="review-h4">JOSÉ CHAVEZ</h3>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="carousel-item">
                        <div style="display: flex; justify-content: center; align-content:space-around">
                            <div clas="block-div">
                                <i class="bi bi-person-circle fa-9x" style="color:white"></i>
                            </div>
                            <div class="block-div">
                                <div class="flex-div" style="flex-direction: column; justify-content: start;">
                                    <i class="bi bi-quote fa-4x quote-icon"></i>
                                    <h2 class="review-h2">"Muy buen servicio. 100% recomandado."</h2>
                                    <h3 class="review-h4">CARLOS ANTONIO</h3>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="carousel-item">
                        <div style="display: flex; justify-content: center; align-content:space-around">
                            <div clas="block-div">
                                <i class="bi bi-person-circle fa-9x" style=" color:white"></i>
                            </div>
                            <div class="block-div">
                                <div class="flex-div" style="flex-direction: column; justify-content: start;">
                                    <i class="bi bi-quote fa-4x quote-icon"></i>
                                    <h2 class="review-h2">"Las consultas empiezan a tiempo, muy buen servicio."</h2>
                                    <h3 class="review-h4">ANGIE SALAZAR</h3>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="carousel-item">
                        <div style="display: flex; justify-content: center; align-content:space-around">
                            <div clas="block-div">
                                <i class="bi bi-person-circle fa-9x" style=" color:white"></i>
                            </div>
                            <div class="block-div">
                                <div class="flex-div" style="flex-direction: column; justify-content: start;">
                                    <i class="bi bi-quote fa-4x quote-icon"></i>
                                    <h2 class="review-h2">"Excelente Atención, mejore mucho en mi forma de dialogar con
                                        mi esposa e hijos."</h2>
                                    <h3 class="review-h4">CARLOS RIERA</h3>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <a class="carousel-control-prev" href="#carousel-review" role="button" data-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="sr-only">Previous</span>
                </a>
                <a class="carousel-control-next" href="#carousel-review" role="button" data-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="sr-only">Next</span>
                </a>
            </div>
        </div>
    </div>
</section>
<!--================Reviews Area =================-->




<!--================Nosotros Area================-->
<section class="nosotros-section" style="padding-bottom: 4rem;">
    <div id="nosotros-div" class="flex-div">
        <div id="nosotros-img-div" class="block-div-np">
            <div id="nosotros-img-inner-div">
                <img id="nosotros-img" src="assets/banners/nosotros_banner.png">
            </div>
        </div>
        <div id="nosotros-title-div" class="block-div-np mt-3">
            <div class="flex-div" style="flex-direction: column;  align-items: baseline;">
                <div style="display:block; margin: auto;">
                    <div class="flex-div"
                        style="flex-direction: column; margin-bottom: 3rem; position: relative; max-height: 175px;">
                        <div style="display: block; margin: auto">
                            <h1 class="block" id="nosotros-shadow" style="color: #e8ebea;">NOSOTROS</h1>
                        </div>
                        <div class="section-subtitle" style="display: block; position:absolute; top: 25%; left: 50%;">
                            <h2 class="block index-title" id="galeria-subtitle"
                                style=" color: #2d9ffd; text-align: center;">
                                ¿POR QUÉ ELEGIRNOS?
                            </h2>
                        </div>
                        <div class="section-title" style="display: block; position:absolute; top: 60%;">
                            <a href="#/galeria">
                                <h1 class="block index-subtitle" id="galeria-title"
                                    style=" color: #283b4a;text-align: center;">NOSOTROS</h1>
                            </a>
                        </div>
                        <div class="underline-div" style="top:90%; max-width: 20rem;"></div>
                    </div>
                </div>

                <div id="nosotros-descripcion-div" style="display: block; margin: auto; margin-top:3rem;">
                    <h5 id="nosotros-descripcion" style=" text-align: center;">Somos un Grupo que brinda servicios
                        apoyando a nuestros clientes
                        Ofrecemos atención de calidad con calidez,
                        profesionalismo, experiencia y seguridad.</h5>
                </div>
            </div>
        </div>
    </div>
</section>
<!--================Nosotros Area================-->

<!--================Donate Area=================-->
<section class="donate_area" >
    <div class="container">
        <div class="column">
            <div class="flex-div" style="margin:auto;">
                <div class="donate_content">
                    <h2 style="text-align: center; font-size: 3rem;">
                        TUS DONACIONES<br />
                        AYUDAN A MANTENER ESTE SITIO
                    </h2>
                    <h3 style="color:white; text-align: center;">
                        "Nos ganamos la vida con lo que recibimos, pero hacemos la vida con
                        lo que damos..."
                    </h3>
                    <!--h5 style="color:white; text-align: center;">-John Maxwell.</h5-->
                </div>
            </div>
            <div class="col-md-6" style="background-color: white; margin: auto; margin-top: 2rem;">
                <div *ngIf="mostrar_donaciones == true" class="donation_form" style="background-color: transparent">
                    <h3 style="color: rgb(0, 0, 0)">¿Quieres realizar una donación?</h3>
                    <a routerLink="/donacion" class="btn btn-info">Donar</a>
                </div>
                <div *ngIf="mostrar_donaciones != true" class="donation_form" style="background-color: transparent">
                    <h3 style="color: rgb(0, 0, 0)">En este momento no se están receptando donaciones</h3>
                </div>
            </div>
        </div>
    </div>
</section>
<!--================Donate Area=================-->

<!--================Event Blog Area=================-->
<section class="event_blog_area mt-5 mb-5">
    <div class="container">
        <div class="flex-div"
            style="flex-direction: column; margin-bottom: 3rem; position: relative; max-height: 175px;">
            <div style="display: block; margin: auto">
                <h1 class="block" id="redes-shadow" style="color: #e8ebea;">REDES SOCIALES</h1>
            </div>
            <div class="section-subtitle" style="display: block; position:absolute; top: 25%; left: 50%;">
                <h2 class="block index-title" id="redes-subtitle" style=" color: #2d9ffd; text-align: center;">
                    PUEDES ENCONTRAR BUENA INFORMACIÓN
                </h2>
            </div>
            <div class="section-title" style="display: block; position:absolute; top: 60%;">
                <h1 class="block index-subtitle" id="redes-title" style=" color: #283b4a;text-align: center;">REDES
                    SOCIALES</h1>
            </div>
            <div class="underline-div" style="top:90%; max-width: 20rem;"></div>
        </div>
        <div class="row text-center">
            <div class="col-md-4">
                <div class="event_post">
                    <a class="twitter-timeline" width="300" height="400" href="https://twitter.com/Mifamiliaec1"
                        data-chrome="nofooter noborders">
                        Tweets @MiFamiliaEC1
                    </a>
                </div>
            </div>
            <div class="col-md-4">
                <div class="event_post">
                    <a class="twitter-timeline" width="300" height="400" href="https://twitter.com/Pontifex_es"
                        data-chrome="nofooter noborders">
                        Tweets @MiFamiliaEC1
                    </a>
                </div>
            </div>
            <div class="col-md-4">
                <!-- <div class="event_post">
                    <a class="twitter-timeline" width="300" height="400" [href]="inforcionContacto.facebook"
                        data-chrome="nofooter noborders">
                        facebook Mifamilia Ecuador
                    </a>
                </div> -->
                <div class="col-md-4">
                    <iframe src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FMifamilia-Ecuador-181191013372657&tabs=timeline&width=300&height=400&small_header=true&adapt_container_width=true&hide_cover=false&show_facepile=true&appId" width="300" height="400" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
                </div>

            </div>
        </div>
    </div>
</section>

<ng-template #content let-modal>
    <div class="modal-header">
        <h4 class="modal-title">{{objActuallity.id_galeria_id.titulo}}</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
        <p>{{objActuallity.id_galeria_id.descripcion}}&hellip;</p>
        <div class="col-sm-12 px-0">
            <div class="col-sm-12 px-0">
                <img src="{{url}}/media/{{objActuallity.image}}" class="bd-placeholder-img card-img-top img-fluid"
                    style="min-height: 330px; min-width: 465px;" alt="{{objActuallity.id_galeria_id.titulo}}">
            </div>

        </div>
    </div>
    <div class="modal-footer d-flex justify-content-between">
        <p class="text-start">{{objActuallity.fecha}}</p>
        <button type="button" class="btn btn-light" (click)="modal.close('Close click')">
            Close
        </button>
    </div>
</ng-template>