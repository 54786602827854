import { Component } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';

import { AngularFireMessaging } from '@angular/fire/messaging';
import { mergeMapTo } from 'rxjs/operators';

import firebase from "firebase/app";
import "firebase/messaging";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'family-proyect';

  showHead: boolean = false;
  showFooter: boolean = false;

  constructor(private router: Router, private afMessaging: AngularFireMessaging) {
    // on route change to '/login', set the variable showHead to false
    router.events.forEach((event) => {
      if (event instanceof NavigationStart) {
        if (event['url'] == '/login' || event['url'] == '/recuperar-contrasenia' || event['url'] == '/registrarse') {
          this.showHead = false;
          this.showFooter = false;

        } else {
          this.showHead = true;
          this.showFooter = true;
        }
      }
    });

    const firebaseConfig = {
      apiKey: "AIzaSyCs1bXrRtG9qfq12D-ArkE6VnKdq-7Nfa8",
      authDomain: "mifamilia-push-notifications.firebaseapp.com",
      projectId: "mifamilia-push-notifications",
      storageBucket: "mifamilia-push-notifications.appspot.com",
      messagingSenderId: "246830489519",
      appId: "1:246830489519:web:664efa354fe861cf86995a",
      measurementId: "G-8PNQMPLMZG"
    };
    // Initialize Firebase
    firebase.initializeApp(firebaseConfig);
    // Initialize Firebase Cloud Messaging and get a reference to the service
    const messaging = firebase.messaging();

    Notification.requestPermission().then((permission) => {
      if (permission === 'granted') {
        console.log('Notification permission granted.');
      }
    });

    messaging.getToken({ vapidKey: 'BCXzMat-si1atHmBROtC9RP5BF8iUoiNtbjsbdGnJbwoqibTfuPGIzx9yk8GnTTStOMBw7zb7T7O4eva3FId75w' }).then((token) => {
      if(token){
        console.log('Token: ', token);
      }else{
        console.log('Ha ocurrido un error al intentar obtener el token');
      }
    }).catch((error) => {
      console.log('Ha ocurrido un error al intentar obtener el token');
    });

    messaging.onMessage((payload)=>{
      console.log("Message received: ", payload);
    });
  }


  ngOnInit() { }


}
