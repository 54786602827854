 <!--================Breadcrumb Area =================-->
 <section class="breadcrumb_area br_image " style="margin-top: 7rem !important;">
    <div class="container">
        <div class="page-cover text-center">
            <h2 class="page-cover-tittle">Tips y Recomendaciones </h2>
            <ol class="breadcrumb">
                <li><a href="index.html">Inicio</a></li>
                <li class="active">Tips</li>
            </ol>
        </div>
    </div>
</section>
<section class="about_area section_gap" style="padding-top: 1px!important;">
    <div class="container">
        <div class="section_title text-center">
        <div *ngFor="let tip of tips | paginate: { itemsPerPage: 2, currentPage: p  }"  class="row" style="padding-top: 5%!important;">
            <div class="col-md-6">
                <div class="about_content flex" style="text-align: center;">
                    <h3 class="title_color">{{tip.titulo| titlecase}}</h3>
                    <div [innerHTML]="tip.descripcion" style="text-align: justify;"></div>
                </div>
            </div>
            <div class="col-md-6">
                <img class="" src="{{mediaUrl}}/{{tip.image}}" style="width: 570px;height: 300px; object-fit: cover!important;">
            </div>
        </div>
        <br/>
    </div>
    <pagination-controls (pageChange)="p = $event" style="text-align: center; margin: auto;" 
        maxSize="6"
        directionLinks="true"
        autoHide="true"
        responsive="true"
        previousLabel="Anterior"
        nextLabel="Siguiente"
    ></pagination-controls>
    </div>
</section>
<!--================Breadcrumb Area =================-->
<!--
<section class="about_area section_gap" style="padding-top: 1px!important;">
    <div class="container">
        <div class="section_title text-center">
        </div>
        <div class="row"  >
            <ngb-carousel *ngIf="images" style="margin: auto!important; outline: none;">
                <ng-template  ngbSlide>
                    <div class="row">
                        <div class="col-md-4">
                            <div class="event_post">
                                <img src="image/blog1.jpg" alt="">
                                <a href="#"><h2 class="event_title">Spreading Peace to world</h2></a>
                                <ul class="list_style sermons_category">
                                    <li><i class="lnr lnr-user"></i>Saturday, 5th may, 2018</li>
                                    <li><i class="lnr lnr-apartment"></i>Rocky beach Church</li>
                                    <li><i class="lnr lnr-location"></i>Santa monica, Los Angeles, USA</li>
                                </ul>
                                <a href="#" class="btn_hover">View Details</a>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="event_post">
                                <img src="image/blog1.jpg" alt="">
                                <a href="#"><h2 class="event_title">Spreading Peace to world</h2></a>
                                <ul class="list_style sermons_category">
                                    <li><i class="lnr lnr-user"></i>Saturday, 5th may, 2018</li>
                                    <li><i class="lnr lnr-apartment"></i>Rocky beach Church</li>
                                    <li><i class="lnr lnr-location"></i>Santa monica, Los Angeles, USA</li>
                                </ul>
                                <a href="#" class="btn_hover">View Details</a>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="event_post">
                                <img src="image/blog1.jpg" alt="">
                                <a href="#"><h2 class="event_title">Spreading Peace to world</h2></a>
                                <ul class="list_style sermons_category">
                                    <li><i class="lnr lnr-user"></i>Saturday, 5th may, 2018</li>
                                    <li><i class="lnr lnr-apartment"></i>Rocky beach Church</li>
                                    <li><i class="lnr lnr-location"></i>Santa monica, Los Angeles, USA</li>
                                </ul>
                                <a href="#" class="btn_hover">View Details</a>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template  ngbSlide>
                    <div class="row">
                        <div class="col-md-4">
                            <div class="event_post">
                                <img src="image/blog1.jpg" alt="">
                                <a href="#"><h2 class="event_title">Spreading Peace to world</h2></a>
                                <ul class="list_style sermons_category">
                                    <li><i class="lnr lnr-user"></i>Saturday, 5th may, 2018</li>
                                    <li><i class="lnr lnr-apartment"></i>Rocky beach Church</li>
                                    <li><i class="lnr lnr-location"></i>Santa monica, Los Angeles, USA</li>
                                </ul>
                                <a href="#" class="btn_hover">View Details</a>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="event_post">
                                <img src="image/blog1.jpg" alt="">
                                <a href="#"><h2 class="event_title">Spreading Peace to world</h2></a>
                                <ul class="list_style sermons_category">
                                    <li><i class="lnr lnr-user"></i>Saturday, 5th may, 2018</li>
                                    <li><i class="lnr lnr-apartment"></i>Rocky beach Church</li>
                                    <li><i class="lnr lnr-location"></i>Santa monica, Los Angeles, USA</li>
                                </ul>
                                <a href="#" class="btn_hover">View Details</a>
                            </div>
                        </div>
                        
                    </div>
                </ng-template>
                <ng-template  ngbSlide>
                    <div class="row">
                    <div class="col-md-6 d_flex" style="margin: auto;">
                        <div class="about_content flex" style="text-align: center;">
                            <h3 class="title_color">Testimonio 1</h3>
                            <p >
                                inappropriate behavior is often laughed off as “boys will be boys,” women face higher conduct standards especially in the workplace. That’s why it’s crucial that, as women, our behavior on the job is beyond reproach. inappropriate behavior is often laughed.                      
                            </p>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <img class="" [src]="images[2]" style="width: 570px;height: 300px; object-fit: cover!important;" alt="Random first slide">
                    </div>
                    </div>
                </ng-template>
            </ngb-carousel>  
           
        </div>
    </div>
</section>
-->
<!--================About Area =================-->
<!--
<section class="event_blog_area section_gap">
    <div class="container">
        <div class="row">
            <div class="col-md-4">
                <div class="event_post">
                    <img src="image/blog1.jpg" alt="">
                    <a href="#"><h2 class="event_title">Spreading Peace to world</h2></a>
                    <ul class="list_style sermons_category">
                        <li><i class="lnr lnr-user"></i>Saturday, 5th may, 2018</li>
                        <li><i class="lnr lnr-apartment"></i>Rocky beach Church</li>
                        <li><i class="lnr lnr-location"></i>Santa monica, Los Angeles, USA</li>
                    </ul>
                    <a href="#" class="btn_hover">View Details</a>
                </div>
            </div>
            <div class="col-md-4">
                <div class="event_post">
                    <img src="image/blog2.jpg" alt="">
                    <a href="#"><h2 class="event_title">Spread Happyness to world</h2></a>
                    <ul class="list_style sermons_category">
                        <li><i class="lnr lnr-user"></i>Saturday, 5th may, 2018</li>
                        <li><i class="lnr lnr-apartment"></i>Rocky beach Church</li>
                        <li><i class="lnr lnr-location"></i>Santa monica, Los Angeles, USA</li>
                    </ul>
                    <a href="#" class="btn_hover">View Details</a>
                </div>
            </div>
            <div class="col-md-4">
                <div class="event_post">
                    <img src="image/blog3.jpg" alt="">
                    <a href="#"><h2 class="event_title">Spreading Light to world</h2></a>
                    <ul class="list_style sermons_category">
                        <li><i class="lnr lnr-user"></i>Saturday, 5th may, 2018</li>
                        <li><i class="lnr lnr-apartment"></i>Rocky beach Church</li>
                        <li><i class="lnr lnr-location"></i>Santa monica, Los Angeles, USA</li>
                    </ul>
                    <a href="#" class="btn_hover">View Details</a>
                </div>
            </div>
        </div>
    </div>
</section>
-->
<!--================About Area =================-->

<!--================About Area =================-->
