import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Observable} from 'rxjs';
import { environment } from 'src/environments/environment.prod';


@Injectable({
  providedIn: 'root'
})
export class TemaService {
  private url: string = environment.apiUrl;

  constructor(private _http: HttpClient) { }

  get_tema(id_tema:any): Observable<any> {
    return this._http.get(this.url+"/api/tema_by_id/?id="+id_tema);
  }

  get_temasAll(id_category:any): Observable<any> {
    return this._http.get(this.url+"/api/tema_by_category/?id="+id_category);
  }
  get_temas_category(id_category:any): Observable<any> {
    return this._http.get(this.url+"/api/temas_images_categoy/?id="+id_category);
  }

   get_imageByTema(id_tema:any): Observable<any> {
    return this._http.get(this.url+"/api/image_by_tema/?id="+id_tema);
  }

  get_videoByTema(id_tema:any): Observable<any> {
    return this._http.get(this.url+"/api/videos_by_tema/?id="+id_tema);
  }

  post_tema_like(tema_id: number, usuario_id: number){
    return this._http.post(this.url+"/api/tema_like/", {'tema_id':tema_id, 'usuario_id': usuario_id})
  }

  post_tema_is_liked(tema_id: number, usuario_id: number){
    return this._http.post(this.url+"/api/tema_is_liked/",{'tema_id':tema_id, 'usuario_id': usuario_id})
  }

  post_tema_fav(tema_id: number, usuario_id: number){
    return this._http.post(this.url+"/api/tema_fav/",{'tema_id':tema_id, 'usuario_id': usuario_id})
  }

  post_tema_is_fav(tema_id: number, usuario_id: number){
    return this._http.post(this.url+"/api/tema_is_fav/",{'tema_id':tema_id, 'usuario_id': usuario_id})
  }

  post_temas_favoritos(usuario_id: number){
    return this._http.post(this.url+'/api/temas_favoritos/', {'usuario_id': usuario_id})
  }

  get_categoria_by_tema(id){
    return this._http.get(this.url+`/api/category_by_tema/${id}/`);
  }

  get_comentatios_tema(tema_id){
    return this._http.get(this.url+'/api/get_comentarios_tema/'+tema_id);
  }

  post_comentar_tema(user_id: number, tema_id: number, content: string){
    return this._http.post(this.url+'/api/post_comentar_tema/', {'user_id': user_id, 'tema_id': tema_id, 'comentario': content});
  }

  post_responder_comentario(user_id: number, comentario_id: number, content: string){
    return this._http.post(this.url+'/api/post_responder_comentario/', {'user_id': user_id, 'comentario_id': comentario_id, 'comentario': content})
  }

}
