export const environment = {
  production: true,
  //apiUrl: "http://127.0.0.1:8000",
  //imgUrl: "http://127.0.0.1:8000",
  apiUrl: "https://chjoguer.pythonanywhere.com",
  imgUrl: "https://chjoguer.pythonanywhere.com",

  firebase: {
    apiKey: "AIzaSyCs1bXrRtG9qfq12D-ArkE6VnKdq-7Nfa8",
    authDomain: "mifamilia-push-notifications.firebaseapp.com",
    projectId: "mifamilia-push-notifications",
    storageBucket: "mifamilia-push-notifications.appspot.com",
    messagingSenderId: "246830489519",
    appId: "1:246830489519:web:664efa354fe861cf86995a",
    measurementId: "G-8PNQMPLMZG"
  },
};
