<!--
 <script src="../../../assets/vendors/owl-carousel/owl.carousel.min.js"></script>
-->
 <section class="breadcrumb_area br_image " style="margin-top: 7rem !important;">
    <div class="container">
        <div class="page-cover text-center">
            <h2 class="page-cover-tittle">Testimonios </h2>
            <ol class="breadcrumb">
                <li><a href="index.html">Inicio</a></li>
                <li class="active">Testimonios</li>
            </ol>
        </div>
    </div>
</section>

<!--
<section class="sermons_work_area section_gap">
    <div class="container">
        <div class="section_title text-center">
            <h2>Sermons This Week</h2>
            <p>The French Revolution constituted for the conscience of the dominant aristocratic class a fall from</p>
        </div>
        <div class="sermons_slider owl-carousel owl-loaded owl-drag">
            
            
            
        <div class="owl-stage-outer"><div class="owl-stage" style="transform: translate3d(-3840px, 0px, 0px); transition: all 1.5s ease 0s; width: 6720px;"><div class="owl-item cloned" style="width: 930px; margin-right: 30px;"><div class="item row">
                <div class="col-lg-8">
                    <div class="sermons_image">
                        <img src="../../../assets/image/breadcrumb.jpg" alt="">
                        <p>inappropriate behavior is often laughed off as “boys will be boys,” women face higher conduct standards especially in the workplace. That’s why it’s crucial that, as women, our behavior on the job is beyond reproach. inappropriate behavior is often laughed.</p>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="sermons_content">
                        <h3 class="title_color">Did not find your Package? Feel free to ask us. We‘ll make it for you</h3>
                        <ul class="list_style sermons_category">
                            <li><i class="lnr lnr-user"></i><span>Categories: </span><a href="#"> Travor James</a></li>
                            <li><i class="lnr lnr-database"></i><span>Sermon Speaker: </span> Prayer</li>
                            <li><i class="lnr lnr-calendar-full"></i><span>Date:</span> 5th may, 2018</li>
                        </ul>
                        <a href="#" class="btn_hover">View More Details</a>
                    </div>
                </div>
            </div></div><div class="owl-item cloned" style="width: 930px; margin-right: 30px;"><div class="item row">
                <div class="col-lg-8">
                    <div class="sermons_image">
                        <img src="../../../assets/image/sermns.jpg" alt="">
                        <p>inappropriate behavior is often laughed off as “boys will be boys,” women face higher conduct standards especially in the workplace. That’s why it’s crucial that, as women, our behavior on the job is beyond reproach. inappropriate behavior is often laughed.</p>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="sermons_content">
                        <h3 class="title_color">Did not find your Package? Feel free to ask us. We‘ll make it for you</h3>
                        <ul class="list_style sermons_category">
                            <li><i class="lnr lnr-user"></i><span>Categories: </span><a href="#"> Travor James</a></li>
                            <li><i class="lnr lnr-database"></i><span>Sermon Speaker: </span> Prayer</li>
                            <li><i class="lnr lnr-calendar-full"></i><span>Date:</span> 5th may, 2018</li>
                        </ul>
                        <a href="#" class="btn_hover btn_hover_two">View More Details</a>
                    </div>
                </div>
            </div></div><div class="owl-item" style="width: 930px; margin-right: 30px;"><div class="item row">
                <div class="col-lg-8">
                    <div class="sermons_image">
                        <img src="../../../assets/image/sermns.jpg" alt="">
                        <p>inappropriate behavior is often laughed off as “boys will be boys,” women face higher conduct standards especially in the workplace. That’s why it’s crucial that, as women, our behavior on the job is beyond reproach. inappropriate behavior is often laughed.</p>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="sermons_content">
                        <h3 class="title_color">Did not find your Package? Feel free to ask us. We‘ll make it for you</h3>
                        <ul class="list_style sermons_category">
                            <li><i class="lnr lnr-user"></i><span>Categories: </span><a href="#"> Travor James</a></li>
                            <li><i class="lnr lnr-database"></i><span>Sermon Speaker: </span> Prayer</li>
                            <li><i class="lnr lnr-calendar-full"></i><span>Date:</span> 5th may, 2018</li>
                        </ul>
                        <a href="#" class="btn_hover">View More Details</a>
                    </div>
                </div>
            </div></div><div class="owl-item" style="width: 930px; margin-right: 30px;"><div class="item row">
                <div class="col-lg-8">
                    <div class="sermons_image">
                        <img src="../../../assets/image/sermns.jpg" alt="">
                        <p>inappropriate behavior is often laughed off as “boys will be boys,” women face higher conduct standards especially in the workplace. That’s why it’s crucial that, as women, our behavior on the job is beyond reproach. inappropriate behavior is often laughed.</p>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="sermons_content">
                        <h3 class="title_color">Did not find your Package? Feel free to ask us. We‘ll make it for you</h3>
                        <ul class="list_style sermons_category">
                            <li><i class="lnr lnr-user"></i><span>Categories: </span><a href="#"> Travor James</a></li>
                            <li><i class="lnr lnr-database"></i><span>Sermon Speaker: </span> Prayer</li>
                            <li><i class="lnr lnr-calendar-full"></i><span>Date:</span> 5th may, 2018</li>
                        </ul>
                        <a href="#" class="btn_hover">View More Details</a>
                    </div>
                </div>
            </div></div><div class="owl-item active" style="width: 930px; margin-right: 30px;"><div class="item row">
                <div class="col-lg-8">
                    <div class="sermons_image">
                        <img src="../../../assets/image/sermns.jpg" alt="">
                        <p>inappropriate behavior is often laughed off as “boys will be boys,” women face higher conduct standards especially in the workplace. That’s why it’s crucial that, as women, our behavior on the job is beyond reproach. inappropriate behavior is often laughed.</p>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="sermons_content">
                        <h3 class="title_color">Did not find your Package? Feel free to ask us. We‘ll make it for you</h3>
                        <ul class="list_style sermons_category">
                            <li><i class="lnr lnr-user"></i><span>Categories: </span><a href="#"> Travor James</a></li>
                            <li><i class="lnr lnr-database"></i><span>Sermon Speaker: </span> Prayer</li>
                            <li><i class="lnr lnr-calendar-full"></i><span>Date:</span> 5th may, 2018</li>
                        </ul>
                        <a href="#" class="btn_hover btn_hover_two">View More Details</a>
                    </div>
                </div>
            </div></div><div class="owl-item cloned" style="width: 930px; margin-right: 30px;"><div class="item row">
                <div class="col-lg-8">
                    <div class="sermons_image">
                        <img src="../../../assets/image/sermns.jpg" alt="">
                        <p>inappropriate behavior is often laughed off as “boys will be boys,” women face higher conduct standards especially in the workplace. That’s why it’s crucial that, as women, our behavior on the job is beyond reproach. inappropriate behavior is often laughed.</p>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="sermons_content">
                        <h3 class="title_color">Did not find your Package? Feel free to ask us. We‘ll make it for you</h3>
                        <ul class="list_style sermons_category">
                            <li><i class="lnr lnr-user"></i><span>Categories: </span><a href="#"> Travor James</a></li>
                            <li><i class="lnr lnr-database"></i><span>Sermon Speaker: </span> Prayer</li>
                            <li><i class="lnr lnr-calendar-full"></i><span>Date:</span> 5th may, 2018</li>
                        </ul>
                        <a href="#" class="btn_hover">View More Details</a>
                    </div>
                </div>
            </div></div><div class="owl-item cloned" style="width: 930px; margin-right: 30px;"><div class="item row">
                <div class="col-lg-8">
                    <div class="sermons_image">
                        <img src="../../../assets/image/sermns.jpg" alt="">
                        <p>inappropriate behavior is often laughed off as “boys will be boys,” women face higher conduct standards especially in the workplace. That’s why it’s crucial that, as women, our behavior on the job is beyond reproach. inappropriate behavior is often laughed.</p>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="sermons_content">
                        <h3 class="title_color">Did not find your Package? Feel free to ask us. We‘ll make it for you</h3>
                        <ul class="list_style sermons_category">
                            <li><i class="lnr lnr-user"></i><span>Categories: </span><a href="#"> Travor James</a></li>
                            <li><i class="lnr lnr-database"></i><span>Sermon Speaker: </span> Prayer</li>
                            <li><i class="lnr lnr-calendar-full"></i><span>Date:</span> 5th may, 2018</li>
                        </ul>
                        <a href="#" class="btn_hover">View More Details</a>
                    </div>
                </div>
            </div></div></div></div><div class="owl-nav disabled"><div class="owl-prev">prev</div><div class="owl-next">next</div></div><div class="owl-dots"><div class="owl-dot"><span></span></div><div class="owl-dot"><span></span></div><div class="owl-dot active"><span></span></div></div></div>
    </div>
</section>
-->



<!--================About Area =================-->
<!--
<section class="about_area section_gap" style="padding-top: 1px!important;">
    <div class="container">
        <div class="section_title text-center">
        </div>
        <div class="row"  >
            <ngb-carousel *ngIf="images" style="margin: auto!important; outline: none;">
                <ng-template  ngbSlide>
                    <div class="row">
                    <div class="col-md-6 d_flex" style="margin: auto;">
                        <div class="about_content flex" style="text-align: center;">
                            <h3 class="title_color">Testimonio 1</h3>
                            <p >
                                inappropriate behavior is often laughed off as “boys will be boys,” women face higher conduct standards especially in the workplace. That’s why it’s crucial that, as women, our behavior on the job is beyond reproach. inappropriate behavior is often laughed.                      
                            </p>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <img class="" [src]="images[0]" style="width: 570px;height: 300px; object-fit: cover!important;" alt="Random first slide">
                    </div>
                    </div>
                </ng-template>
                <ng-template  ngbSlide>
                    <div class="row">
                    <div class="col-md-6 d_flex" style="margin: auto;">
                        <div class="about_content flex" style="text-align: center;">
                            <h3 class="title_color">Testimonio 1</h3>
                            <p >
                                inappropriate behavior is often laughed off as “boys will be boys,” women face higher conduct standards especially in the workplace. That’s why it’s crucial that, as women, our behavior on the job is beyond reproach. inappropriate behavior is often laughed.                      
                            </p>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <img class="" [src]="images[1]" style="width: 570px;height: 300px; object-fit: cover!important;" alt="Random first slide">
                    </div>
                    </div>
                </ng-template>
                <ng-template  ngbSlide>
                    <div class="row">
                    <div class="col-md-6 d_flex" style="margin: auto;">
                        <div class="about_content flex" style="text-align: center;">
                            <h3 class="title_color">Testimonio 1</h3>
                            <p >
                                inappropriate behavior is often laughed off as “boys will be boys,” women face higher conduct standards especially in the workplace. That’s why it’s crucial that, as women, our behavior on the job is beyond reproach. inappropriate behavior is often laughed.                      
                            </p>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <img class="" [src]="images[2]" style="width: 570px;height: 300px; object-fit: cover!important;" alt="Random first slide">
                    </div>
                    </div>
                </ng-template>
            </ngb-carousel>  
           
        </div>
    </div>
</section>
-->
<section class="event_blog_area section_gap">
    <div class="container">
        <div class="row ministries_info">
            <div *ngFor="let testim of testimonios | paginate: { itemsPerPage: 6, currentPage: p  }"  class="col-lg-6 col-md-6 col-sm-6">
                <div class="event_post"  style="text-align: justify;">
                    <!-- <img src="image/blog1.jpg" alt="">-->
                    <p class="event_title" style="text-align: center; margin: auto; font-size: xx-large;" >{{testim.titulo | titlecase}}</p>
                    <p style="text-align: justify; margin: auto; font-style: italic; font-size: x-large;">"{{testim.descripcion}}"</p>
                    <p style="text-align: center; margin: auto; font-style: normal; font-size: large;">-{{testim.usuario | titlecase}} ({{testim.fecha}})</p>
                </div>
            </div>
        </div>
    </div>
    <br>
    <br>
    <br>
    <pagination-controls (pageChange)="p = $event" style="text-align: center; margin: auto;" 
        maxSize="6"
        directionLinks="true"
        autoHide="true"
        responsive="true"
        previousLabel="Anterior"
        nextLabel="Siguiente"
    ></pagination-controls>

<!--================About Area =================-->
    <div class="container">
        <div class="comment-form">
            <h4>Envianos tu testimonio</h4>
            <form [formGroup]="registerForm" (ngSubmit)="sendTestimonios()" >
                <div class="form-group form-inline">
                  <div class="form-group col-lg-6 col-md-6 name">
                    <input type="text" class="form-control"  formControlName="name" id="name" placeholder="Nombres" onfocus="this.placeholder = ''" onblur="this.placeholder = 'Nombres'"  [ngClass]="{ 'is-invalid': submitted && f.name.errors }">
                    <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                        <div *ngIf="f.name.errors.required">Los Nombres son requeridos</div>
                    </div>
                </div>
                  <div class="form-group col-lg-6 col-md-6 email">
                    <input type="email"  formControlName="email" class="form-control" id="email" placeholder="Correo" onfocus="this.placeholder = ''" onblur="this.placeholder = 'Correo'" [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                    <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                        <div *ngIf="f.email.errors.required">Email es requerido</div>
                        <div *ngIf="f.email.errors.email">Email debe de ser una dirección valida</div>
                    </div>
                  </div>										
                </div>
                <div class="form-group">
                    <input type="text" class="form-control" maxlength="35" formControlName="title" id="subject" placeholder="Tema del testimonio" onfocus="this.placeholder = ''" onblur="this.placeholder = 'Tema del testimonio'" [ngClass]="{ 'is-invalid': submitted && f.title.errors }">
                    <div *ngIf="submitted && f.title.errors" class="invalid-feedback">
                        <div *ngIf="f.title.errors.required">El asunto es requerido</div>
                    </div>
                </div>
                <div class="form-group">
                    <textarea class="form-control mb-10" maxlength="250" formControlName="description" rows="5" name="message" placeholder="Escriba su testimonio (máximo 250 cáracteres)" onfocus="this.placeholder = ''" onblur="this.placeholder = 'Escriba su testimonio (máximo 250 cáracteres)'" required="" [ngClass]="{ 'is-invalid': submitted && f.description.errors }"></textarea>
                    <div *ngIf="submitted && f.description.errors" class="invalid-feedback">
                        <div *ngIf="f.description.errors.required">Tu opinión es requerida</div>
                        <div *ngIf="f.description.errors.minlength">Minimo 10 caracateres debes de ingresar</div>
                    </div>
                </div>
                <button type="submit" value="submit" (click)="set()" class="btn btn_hover btn_hover_two">Enviar</button>

            </form>
            <div  *ngIf="showError" role="alert" class="alert alert-warning alert-dismissible fade show"><strong >Lo sentimos!</strong> Debes de iniciar sesion para poder poder enviar tu testimonio. </div>
            
            <div *ngIf="showMessage" role="alert" class="alert alert-success alert-dismissible fade show"><strong >Envio exitoso!</strong>Tu testimonio ha sido enviado correctamente lo chequearemos para su respectiva publicación. </div>

        </div>
    </div>
</section>

<!--================About Area =================-->
