import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ViewEncapsulation,
  ChangeDetectorRef
} from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NgbDate, NgbDateStruct, NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { LoginService } from '../login/login.service';
import { Consejeria, Consejero, EmpleadoRol, Tema, TemaConsejeria } from './interfaces';
import { AgendarService } from './service/agendar.service';

@Component({
  selector: 'app-agendar-citas',
  templateUrl: './agendar.citas.component.html',
  styleUrls: ['./agendar.citas.component.css'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class AgendarcitasComponent implements OnInit {
  
  constructor(
    private loginServices: LoginService,
    private modalService: NgbModal,
    private consejeria_services: AgendarService,
    private changeDetection: ChangeDetectorRef
  ) { }

  user_id: number = null;
  user = null;

  consejeros: Consejero[] = [];
  temas: Tema[] = [];
  horas: string[] = [];
  min_date: string = "";


  consejero_id: number = null;
  tema_id: number;
  modalidad: string;


  ngOnInit(): void {
    this.user_id = this.loginServices.getUserId();
    this.user = this.loginServices.getUser();

    this.min_date = new Date().toISOString().slice(0, 10);

    this.consejeria_services.getConsejerosDisponibles().subscribe(data => {
      this.consejeros = data as Consejero[]
      this.changeDetection.detectChanges();
      console.log(this.consejeros)
    })


    //this.consejeros = this.consejeria_services.get_empleados_rol()

    this.consejeria_services.get_tema_consejeria().subscribe(data => {
      this.temas = data as Tema[]
      this.changeDetection.detectChanges();
    })
  }

  onConsejeroChange(consejero_id: number){
    console.log(consejero_id)
    this.horas = [];
    this.consejero_id = consejero_id;
  }

  onDateChange(fecha: string) {
    if(this.consejero_id == null) return;
    this.consejeria_services.getHorasDisponiblesConsejeroFecha(this.consejero_id, fecha).subscribe(data=>{
      console.log(data)
      if(data['status']){
        this.horas = data['horas'] as string[];
        this.changeDetection.detectChanges();
      }
    })
  }

  validarForm(consejero_id: number,
            tema_id: number,
            modalidad: string,
            fecha: string,
            hora: string,
            motivo: string,
            ){
    let valid = consejero_id != null && tema_id != null && modalidad != null && fecha!= null && hora != null && (motivo != null && motivo != "")
    if(!valid){
      alert('Debe completar todos los campos!');
    }
    return valid
  }

  onSubmit(consejero_id: number,
          tema_id: number,
          modalidad: string,
          fecha: string,
          hora: string,
          motivo: string,
          acept_modal,
          success_modal
          ){
    if(this.validarForm(consejero_id,tema_id,modalidad,fecha,hora,motivo)){
      let consejeria = {
        usuario: this.user_id,
        consejero: consejero_id,
        tema: tema_id,
        modalidad: modalidad,
        fecha: fecha,
        hora: hora,
        motivo: motivo,
        link: '',
        direccion:'',
        precio: 0
      }
      if(modalidad == 'PRESENCIAL')consejeria.direccion = 'Ciudadela Coviem , Mz: 8 Villa 11';


      this.modalService.open(acept_modal).result.then(result=>{
        if(result == 'submit'){
          this.consejeria_services.post_agendar_consejerias(consejeria).subscribe(data=>{
            this.modalService.open(success_modal).result.then(result=>{
              window.location.reload();
            },
            reason=>{
              window.location.reload();
            })
            
          }, 
          error=>{
      
          });
        }
      })

    }
    
  }


}
