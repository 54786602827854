import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Consejeria } from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class HistorialCitasService {

  private url: string = environment.apiUrl;

  constructor(private _http: HttpClient) {}

  get_consejeria_usuario_terminadas(userId: number): Observable<Consejeria[]> {
    return this._http.get<Consejeria[]>(`${this.url}/api/get_consejeria_usuario_terminadas/${userId}`)
  }

  get_consejeria_usuario_pendientes(userId: number): Observable<Consejeria[]> {
    return this._http.get<Consejeria[]>(`${this.url}/api/get_consejeria_usuario_pendientes/${userId}`);
  }

  editar_consejeria( consejeriaPost: any): Observable<string> {
    return this._http.post<string>(`${this.url}/api/editar_consejeria`,consejeriaPost)
  }

  eliminar_consejeria_usuario(conjid:number): Observable<string> {
    return this._http.post<string>(`${this.url}/api/eliminar_consejeria_usuario`,{'conjid':conjid})
  }

  getHorasDisponiblesConsejeroFecha(consejero_id: number, fecha: string){
    return this._http.get(this.url+'/api/get_horas_disponibles_consejero_fecha/'+ consejero_id +'/'+ fecha);
  }

  getConsejerosDisponibles(){
    return this._http.get(this.url+'/api/get_consejeros_disponibles/');
  }


}
