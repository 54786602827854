
        
        <!--================Header Area =================-->
        <header class="header_area"></header>
        <!--================Header Area =================-->
        <!--================Banner Area =================-->
        <!-- <section class="banner_area blog_banner d_flex align-items-center" >
            <div class="overlay bg-parallax" data-stellar-ratio="0.9" data-stellar-vertical-offset="0" data-background=""></div>
            <div class="container">
                <div class="banner_content text-center">
                    <h4>Dude You’re Getting <br />a Telescope</h4>
                    <p>There is a moment in the life of any aspiring astronomer that it is time to buy that first</p>
                    <a href="#" class="btn btn_hover">View More</a>
                </div>
            </div>
        </section> -->
        <section class="breadcrumb_area br_image " >
            <div class="container">
                <div class="page-cover text-center">
                    <h2 class="page-cover-tittle">Nuestros Temas</h2>
                    <ol class="breadcrumb">
                        <li><a href="index.html">Inicio</a></li>
                        <li class="active">Categorías</li>
                    </ol>
                </div>
            </div>
        </section>
        <!--================Banner Area =================-->
        
        <!--================Blog Categorie Area =================-->
        <!-- <section class="blog_categorie_area">
            <div class="container">
                <div class="row">
                    <div class="col-lg-4">
                        <div class="categories_post">
                            <img src="image/blog/cat-post/cat-post-3.jpg" alt="post">
                            <div class="categories_details">
                                <div class="categories_text">
                                    <a href="blog-details.html"><h5>Social Life</h5></a>
                                    <div class="border_line"></div>
                                    <p>Enjoy your social life together</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="categories_post">
                            <img src="image/blog/cat-post/cat-post-2.jpg" alt="post">
                            <div class="categories_details">
                                <div class="categories_text">
                                    <a href="blog-details.html"><h5>Politics</h5></a>
                                    <div class="border_line"></div>
                                    <p>Be a part of politics</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="categories_post">
                            <img src="image/blog/cat-post/cat-post-1.jpg" alt="post">
                            <div class="categories_details">
                                <div class="categories_text">
                                    <a href="blog-details.html"><h5>Food</h5></a>
                                    <div class="border_line"></div>
                                    <p>Let the food be finished</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section> -->
        <!--================Blog Categorie Area =================-->
        
        <!--================Blog Area =================-->
        <section class="blog_area">
            <div class="container">
                <div class="row">
                    <div  class="col-lg-8">
                        <div class="blog_left_sidebar">
                            <article *ngFor="let tema of temas_by_categorys | paginate: { itemsPerPage: 2, currentPage: p }" class="row blog_item">
                               <div class="col-md-3">
                                   <div class="blog_info text-right">
                                        <ul class="blog_meta list_style">
                                            <!-- <li><a href="#">Mark wiens<i class="lnr lnr-user"></i></a></li> -->
                                            <li><a href="#">{{tema.fecha}}<i class="lnr lnr-calendar-full"></i></a></li>
                                            <!-- <li><a href="#">1.2M Views<i class="lnr lnr-eye"></i></a></li> -->
                                            <!-- <li><a href="#">06 Comments<i class="lnr lnr-bubble"></i></a></li> -->
                                        </ul>
                                    </div>
                               </div>
                                <div class="col-md-9">
                                    <div class="blog_post">
                                        <img src="{{url}}{{tema.images}}" style="width: 680px!important; height: 280px!important;" alt="">
                                        <div class="blog_details">
                                            <a [routerLink]="['/tema-details',tema.id_tema,tema.categoria]"><h2>{{tema.titulo| titlecase}}</h2></a>

                                            <div [innerHTML]="tema.descripcion | slice:0:300" style="text-align: justify;"></div>
                                            <a [routerLink]="['/tema-details',tema.id_tema,tema.categoria]" class="view_btn button_hover">Leer más</a>
                                        </div>
                                    </div>
                                </div>
                            </article>
                            <nav class="blog-pagination justify-content-center d-flex">
                                <pagination-controls (pageChange)="p = $event" style="text-align: center; margin: auto;" 
                                maxSize="6"
                                directionLinks="true"
                                
                                responsive="true"
                                previousLabel="Anterior"
                                nextLabel="Siguiente"
                                ></pagination-controls>
		                        <!-- <ul class="pagination">
		                            <li class="page-item">
		                                <a href="#" class="page-link" aria-label="Previous">
		                                    <span aria-hidden="true">
                                                <span class="lnr lnr-chevron-left"></span>
                                                autoHide="true"
		                                    </span>
		                                </a>
		                            </li>
		                            <li class="page-item"><a href="#" class="page-link">01</a></li>
		                            <li class="page-item active"><a href="#" class="page-link">02</a></li>
		                            <li class="page-item"><a href="#" class="page-link">03</a></li>
		                            <li class="page-item"><a href="#" class="page-link">04</a></li>
		                            <li class="page-item"><a href="#" class="page-link">09</a></li>
		                            <li class="page-item">
		                                <a href="#" class="page-link" aria-label="Next">
		                                    <span aria-hidden="true">
		                                        <span class="lnr lnr-chevron-right"></span>
		                                    </span>
		                                </a>
		                            </li>
		                        </ul> -->
                            </nav>
                            
                        </div>
                       
                    </div>
                    
                    <div class="col-lg-4">
                        <div class="blog_right_sidebar">
                           
                            <aside class="single_sidebar_widget ads_widget">
                                <a href="#"><img class="img-fluid" src="image/blog/add.jpg" alt=""></a>
                                <div class="br"></div>
                            </aside>
                            <aside class="single_sidebar_widget post_category_widget">
                                <h4 class="widget_title">Categorias</h4>
                                <ul *ngFor="let ct of categorias" class="list_style cat-list">
                                    <li  [ngClass]="{'active': selectedItem == ct.nombre_categoria}" (click)="listClick($event, ct.nombre_categoria)">
                                        <a  [routerLink]="['/temas',ct.id_categoria_tema ]" class="d-flex justify-content-between">
                                            <p>{{ct.nombre_categoria}}</p>
                                            <p></p>
                                        </a>														
                                </ul>
                                <div class="br"></div>
                            </aside>
                          
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!--================Blog Area =================-->
        