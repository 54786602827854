<div class="card">
    <div style="position: relative; min-height: 13.5rem; min-width: 18rem; max-height: 13.5rem; max-width: 18rem;">
        <img class="card-img shadow" src="{{url}}{{tema.image}}">
        <div class="rounded-circle"
            style="background-color: #00c8e6; width: 60px; height: 60px; position: absolute; top:67%; left:5%;">
            <h3 style="font-size: 1rem; text-align:center; margin-top: 5px; color: white;">
                {{getMonth(tema.fecha)}}<br />{{getDay(tema.fecha)}}
            </h3>
        </div>
        <div style="position: absolute; right: 5%; bottom: 3%;">
            <i class="bi bi-person-circle" style="margin-right: 0.5rem;"></i>
            <span>{{tema.autor}}</span>
        </div>
    </div>
    <div class="card-body" style="padding: 0px; min-height: 12rem; min-width: 18rem; max-height: 12rem; max-width: 18rem;">
        <h5 class="card-title" style="color: #2d9ffd; margin-left: 1rem;">
            {{ tema.categoria }}
        </h5>
        <div class="d-flex" style="min-height: 8rem; max-height: 8rem; overflow: hidden; padding: auto;">
            <div style="margin: auto; margin-left: 5%; margin-right: 5%; font-size: 1.3rem; text-align:center;">{{ tema.titulo }}</div>
        </div>
        <div class="fixed-bottom position-absolute mb-3 ml-3" style="left: 28%">
            <a *ngIf="tema.categoria_id" href="#/tema-details/{{ tema.id }}/{{ tema.categoria_id}}"
                class="btn btn-primary rounded-pill shadow">Ver más</a>
            <a *ngIf="tema.id_categoria" href="#/tema-details/{{ tema.id }}/{{ tema.id_categoria}}"
                class="btn btn-primary rounded-pill shadow">Ver más</a>
        </div>
    </div>
</div>