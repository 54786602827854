<div class="container">
    <h1 style="margin-top: 7rem;">Perfil</h1>
    <div class="btn-group" role="group" aria-label="Basic example">
        <button id="perfil_btn" type="button" class="btn btn-outline-primary" (click)="mi_perfil_pressed()">Mi
            perfil</button>
        <button id="set_info_btn" type="button" class="btn btn-outline-primary" (click)="editar_info_pressed()">Editar
            información</button>
        <button id="set_foto_btn" type="button" class="btn btn-outline-primary" (click)="editar_foto_pressed()">Cambiar
            foto</button>
        <button id="set_pass_btn" type="button" class="btn btn-outline-primary" (click)="editar_pass_pressed()">Cambiar
            contraseña</button>
    </div>
    <div class="card" style="width: 100%; padding: 2rem;">
        <div *ngIf="tab=='perfil'" class="d-flex flex-row align-items-center flex-wrap">
            <div id="profile-foto-div">
                <div class="d-flex">
                    <img *ngIf="usuario?.foto" id="profile-foto" [src]="apiURL+usuario.foto">
                </div>
            </div>
            <div id="profile-info-div">
                <h1>{{usuario?.first_name}}&nbsp;{{usuario?.last_name}}</h1>
                <h3>@{{usuario?.username}}</h3>
                <div class="info-grid">
                    <p style="grid-area: a; font-weight: bold;"><i class="bi bi-gender-ambiguous"></i>Genero:</p>
                    <p style="grid-area: b;" *ngIf="usuario?.genero=='M'">Masculino</p>
                    <p style="grid-area: b;" *ngIf="usuario?.genero=='F'">Femenino</p>

                    <p style="grid-area: c; font-weight: bold;"><i class="bi bi-balloon-fill"></i>Nacimiento:</p>
                    <p style="grid-area: d;">{{usuario?.nacimiento}}</p>

                    <p style="grid-area: e; font-weight: bold;"><i class="bi bi-envelope"></i>Correo:</p>
                    <p style="grid-area: f;">{{usuario?.email}}</p>

                    <p style="grid-area: g; font-weight: bold;"><i class="bi bi-person-vcard"></i>Cédula:</p>
                    <div style="grid-area: h;">
                        <p *ngIf="usuario?.cedula; else no_cedula" style="grid-area: h;">{{usuario?.cedula}}</p>
                        <ng-template #no_cedula>
                            <p>Sin registrar</p>
                        </ng-template>
                    </div>

                    <p style="grid-area: i; font-weight: bold;"><i class="bi bi-telephone"></i>Teléfono:</p>
                    <div style="grid-area: j;">
                        <p *ngIf="usuario?.telefono; else no_telefono">{{usuario?.telefono}}</p>
                        <ng-template #no_telefono>
                            <p>Sin registrar</p>
                        </ng-template>
                    </div>
                </div>
            </div>

        </div>
        <div class="mt-4" *ngIf="tab=='pass'">
            <h2>Cambiar contraseña</h2>
            <form>
                <div class="form-group">
                    <label for="exampleInputPassword1">Antigua contraseña</label>
                    <input #password type="password" class="form-control" id="exampleInputPassword1"
                        placeholder="Password">
                </div>
                <div class="form-group">
                    <label for="exampleInputPassword1">Nueva contraseña</label>
                    <input #new_password type="password" class="form-control" id="exampleInputPassword1"
                        placeholder="Password">
                </div>
                <div class="form-group">
                    <label for="exampleInputPassword1">Repetir nueva contraseña</label>
                    <input #repeat_password type="password" class="form-control" id="exampleInputPassword1"
                        placeholder="Password">
                </div>

                <button type="submit" class="btn btn-primary"
                    (click)="onSubmit_setPassword(password.value, new_password.value, repeat_password.value)">Cambiar</button>
            </form>
        </div>
        <div class="mt-4" *ngIf="tab=='foto'">
            <h2>Cambiar foto de perfil</h2>
            <div id="profile-foto-div">
                <div class="d-flex">
                    <img id="preview-foto" [src]="apiURL+usuario.foto">
                </div>
            </div>
            <form>

                <div class="form-group">
                    <label for="exampleInputPassword1">Seleccione una imagen</label>
                    <input type="file" class="form-control" (change)="onImageChange()" id="profile-img-picker"
                        name="avatar" accept="image/png, image/jpeg">
                    <div class="form-group">
                        <label for="exampleInputPassword1">Introduzca su contraseña</label>
                        <input type="password" class="form-control" id="foto-form-password" placeholder="Contraseña">
                    </div>
                </div>


                <button type="submit" class="btn btn-primary" (click)="onUploadProfileImage()">Guardar cambios</button>
            </form>
        </div>
        <div class="mt-4" *ngIf="tab=='info'">
            <h2>Editar mi información</h2>
            <form>
                <div class="form-group">
                    <label for="exampleInputPassword1">Nombres</label>
                    <input type="text" class="form-control" id="nombresInput" [placeholder]="usuario.first_name">
                </div>
                <div class="form-group">
                    <label for="exampleInputPassword1">Apellidos</label>
                    <input type="text" class="form-control" id="apellidosInput" [placeholder]="usuario.last_name">
                </div>
                <div class="form-group">
                    <label for="exampleInputPassword1">Nacimiento</label>
                    <input type="date" class="form-control" id="nacimientoInput" [value]="usuario.nacimiento">
                </div>
                <div class="form-group">
                    <label for="exampleInputPassword1">Cédula</label>
                    <input type="text" class="form-control" id="cedulaInput" [placeholder]="usuario.cedula">
                </div>
                <div class="form-group">
                    <label for="exampleInputPassword1">Teléfono</label>
                    <input type="text" class="form-control" id="telefonoInput" [placeholder]="usuario.telefono">
                </div>
                
                <div class="form-group">
                    <h5 for="exampleInputPassword1">Ingrese su contraseña</h5>
                    <input #password_setInfo type="text" class="form-control" id="exampleInputPassword1">
                </div>
                <button type="submit" class="btn btn-primary" (click)="onSubmit_setInfo(password_setInfo.value)">Guardar cambios</button>
            </form>
        </div>
    </div>

</div>