import { Component, OnInit } from '@angular/core';
import { NotificacionesService } from '../header/services/notificaciones.service';
import { InformacionContactato } from 'src/app/components/index/interfaces';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  public inforcionContacto: InformacionContactato = null
  year: number;
  constructor(
    private notificacionesService: NotificacionesService
  ) { }

  ngOnInit(): void {
    this.year = (new Date()).getFullYear();
    this.notificacionesService.getInfomacionContacto().subscribe((data) => {
      this.inforcionContacto = data
    })
  }


}
