import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { environment } from 'src/environments/environment.prod';


@Injectable({
  providedIn: 'root'
})
export class PublicidadService {
  private url: string = environment.apiUrl;
  constructor(private http: HttpClient) { }

  get_publicidades() {
    return this.http.get(this.url+"/api/get_publicidades/");
  }

}
