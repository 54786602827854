<!--================Header Area =================-->
<header class="header_area"></header>
<!--================Header Area =================-->
<!--================Breadcrumb Area =================-->
<section class="breadcrumb_area blog_banner_two ">
    <div class="overlay bg-parallax" data-stellar-ratio="0.8" data-stellar-vertical-offset="0" data-background=""></div>
    <div class="container">
        <div class="page-cover text-center">
            <h2 class="page-cover-tittle f_48">Temas</h2>
            <!--ol class="breadcrumb">
                <li><a href="index.html">Home</a></li>
                <li><a href="blog.html">Tema</a></li>
            </ol-->
        </div>
    </div>
</section>
<!--================Breadcrumb Area =================-->
<!--================Blog Area =================-->
<section class="blog_area single-post-area">
    <div class="container">
        <div class="row">
            <div *ngFor="let tema of tema_current" class="col-lg-8 posts-list">
                <div class="single-post row">
                    <div class="col-lg-12">
                        <div class="feature-img">
                            <img class="img-fluid" src="{{url}}/media/{{images_temas[0].image}}"
                                style="width:800px; height:400px; object-fit: cover!important;" alt="images">
                        </div>
                        <!-- <div class="fb-like" data-href="http://chjoguer.pythonanywhere.com/" data-width="" data-layout="button_count"  data-action="like" data-size="small" data-share="true"></div> -->
                    </div>

                    <div *ngIf="isLogged; else not_logged" class="col-lg-12 mt-2">
                        <div class="d-flex justify-content-end">
                            <div style="margin-right: 2rem;" (click)="onLikeClicked()">
                                <div class="d-flex align-items-center">
                                    <span
                                        style="font-size: 1.2rem; margin-right: 0.5rem; margin-top:0.2rem;">{{like_count}}</span>
                                    <i *ngIf="is_liked; else not_liked" class="bi bi-star-fill fa-2x"
                                        style="color: rgb(231, 231, 77);"></i>
                                    <ng-template #not_liked><i class="bi bi-star fa-2x"></i></ng-template>
                                </div>

                            </div>
                            <div style="margin-right: 1rem;" (click)="onFavClicked()">
                                <i *ngIf="is_fav; else not_fav" class="bi bi-bookmark-fill fa-2x"></i>
                                <ng-template #not_fav><i class="bi bi-bookmark fa-2x"></i></ng-template>
                            </div>
                        </div>
                    </div>

                    <ng-template #not_logged>
                        <div class="col-lg-12 mt-2">
                            <div class="d-flex justify-content-end">
                                <div style="margin-right: 2rem;">
                                    <div class="d-flex align-items-center">
                                        <span
                                            style="font-size: 1.2rem; margin-right: 0.5rem; margin-top:0.2rem;">{{like_count}}</span>
                                        <i class="bi bi-star fa-2x" (click)="onFavLike_notLoggedIn(no_auth_modal)"></i>
                                    </div>
                                </div>
                                <div style="margin-right: 1rem;">
                                    <i class="bi bi-bookmark fa-2x" (click)="onFavLike_notLoggedIn(no_auth_modal)"></i>
                                </div>
                            </div>
                        </div>
                    </ng-template>


                    <div class="col-lg-12 col-md-12 blog_details">
                        <h2>{{tema.titulo| titlecase}}</h2>
                        <div [innerHTML]="tema.descripcion" style="text-align: justify;"></div>

                    </div>
                    <div class="col-lg-12">
                        <iframe class="col-12" [src]="video" width="560" height="315" frameborder="0" allowfullscreen>
                        </iframe>

                    </div>
                </div>
                <div class="comments-area rounded-lg">
                    <h4 id="comment-section-title">Comentarios</h4>
                    <div class="comment-div" *ngFor="let comentario of comentarios">
                        <div class="d-flex flex-row align-items-center" style="min-width: 100%">
                            <img [src]="mediaURL+'/media/'+comentario.foto"
                                class="comment-img rounded-circle align-self-start">
                            <div class="flex-fill">
                                <div class="d-flex flex-column ">
                                    <div>
                                        <div class="d-flex flex-row align-items-center justify-content-between">
                                            <div>
                                                <div class="flex-row align-items-center">
                                                    <span id="comentario-usuario">{{comentario.usuario}}&nbsp;</span>
                                                    <span>-</span>
                                                    <span>&nbsp;{{datetime_to_comment_format(comentario.fecha, comentario.hora)}}</span>
                                                </div>
                                            </div>
                                            <button type="button" class="btn btn-link" data-toggle="collapse"
                                                [attr.data-target]="'#comment_'+comentario.id" aria-expanded="false"
                                                aria-controls="comment">
                                                <i class="bi bi-reply-fill">Responder</i>
                                            </button>
                                        </div>
                                    </div>
                                    <p class="comment-content">{{comentario.content}}</p>
                                    <div *ngIf="comentario.respuestas.length > 0" class="respuesta-coment-div">
                                        <div class="comment-div" *ngFor="let respuesta of comentario.respuestas">
                                            <div class="d-flex flex-row align-items-center">
                                                <img [src]="mediaURL+'/media/'+respuesta.foto"
                                                    class="comment-img rounded-circle align-self-start">
                                                <div>
                                                    <div class="d-flex flex-column ">
                                                        <div>
                                                            <div class="d-flex flex-row align-items-center">
                                                                <span
                                                                    id="respuesta-usuario">{{respuesta.usuario}}&nbsp;</span>
                                                                <span>-</span>
                                                                <span>&nbsp;{{datetime_to_comment_format(respuesta.fecha, respuesta.hora)}}</span>
                                                            </div>
                                                        </div>
                                                        <p class="comment-content">{{respuesta.content}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="collapse" id="comment_{{comentario.id}}">
                                        <div class="card card-body">
                                            <div class="form-group">
                                                <label for="respuesta_{{comentario.id}}_textArea">Escribe aquí tu respuesta a este comentario.</label>
                                                <textarea  class="form-control" id="respuesta_{{comentario.id}}_textArea"rows="3"></textarea>
                                                <div class="d-flex justify-content-end mt-2">
                                                    <button class="btn btn-danger" style="margin-right: 0.5rem;" data-toggle="collapse"
                                                    [attr.data-target]="'#comment_'+comentario.id" aria-expanded="false"
                                                    aria-controls="comment">
                                                        Cancelar
                                                    </button>
                                                    <button class="btn btn-primary" (click)="on_responder_comentario(comentario.id,no_auth_modal, comment_len_modal)">
                                                        <i class="bi bi-send"></i>
                                                        Responder
                                                    </button>
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                    <div *ngIf="comentarios.length == 0">
                        <h4>Aún no hay comentarios. Puedes ser el primero en comentar sobre este tema.</h4>
                    </div>
                    <div>
                        <div class="card card-body">
                            <div class="form-group">
                                <label for="comentario_tema_textArea">Comenta aquí sobre este tema.</label>
                                <textarea class="form-control" id="comentario_tema_textArea"rows="3"></textarea>
                                <div class="d-flex justify-content-end mt-2">
                                    <button class="btn btn-primary" (click)="on_conmentar_tema(no_auth_modal, comment_len_modal)">
                                        <i class="bi bi-send"></i>
                                        Comentar tema
                                    </button>
                                </div>
                                
                            </div>
                        </div>
                    </div>

                </div>
            </div>


            <div class="col-lg-4">
                <div class="blog_right_sidebar">
                    <aside class="single-sidebar-widget newsletter_widget">
                        <app-publicidad></app-publicidad>
                    </aside>
                    <aside class="single-sidebar-widget newsletter_widget">
                        <h4 class="widget_title">Asesorías</h4>
                        <p>
                            Ofrecemos nuestra ayuda para que puedas hablar con nosotros
                            acerca de estos temas
                        </p>
                        <div class="form-group d-flex flex-row">
                            <a (click)="goToConsejerias()" class="col-12 bbtns info">Solicitar</a>

                        </div>
                        <div *ngIf="message" class="alert alert-warning alert-dismissible fade show" role="alert">
                            <strong>Lo sentimos!</strong> Debes de iniciar sesion para poder solicitar asesorías.
                        </div>

                        <div class="br"></div>
                    </aside>

                    <aside class="single_sidebar_widget popular_post_widget">
                        <h3 class="widget_title">Favoritos</h3>
                        <div *ngFor="let tema of temas_favoritos" class="media post_item">
                            <!-- <img src="http://127.0.0.1:8000" alt="post"> -->
                            <div class="media-body">
                                <a href="#/tema-details/{{ tema.id }}/{{ tema.categoria_id }}"
                                    (click)="onClickLinkFavoritos(tema)">
                                    <h3>{{tema.titulo}}</h3>
                                </a>
                                <p>{{tema.fecha}}</p>
                            </div>
                        </div>
                        <div class="br"></div>
                    </aside>

                    <aside class="single_sidebar_widget popular_post_widget">
                        <h3 class="widget_title">Más Temas</h3>
                        <div *ngFor="let tema of tema_by_categorys" class="media post_item">
                            <!-- <img src="http://127.0.0.1:8000" alt="post"> -->
                            <div class="media-body">
                                <a href="#/tema-details/{{ tema.id_tema }}/{{ tema.tema_categoria_id }}"
                                    (click)="onClickLink(tema)">
                                    <h3>{{tema.titulo}}</h3>
                                </a>
                                <p>{{tema.fecha}}</p>
                            </div>
                        </div>

                        <div class="br"></div>
                    </aside>
                    <aside class="single_sidebar_widget ads_widget">
                        <a href="#"><img class="img-fluid" src="image/blog/add.jpg" alt=""></a>
                        <div class="br"></div>
                    </aside>
                    <aside class="single_sidebar_widget post_category_widget">
                        <h4 class="widget_title">Otras Categorías</h4>
                        <ul class="list_style cat-list">
                            <li *ngFor="let ct of categorias">
                                <a [routerLink]="['/temas',ct.id_categoria_tema ]"
                                    class="d-flex justify-content-between">
                                    <p>{{ct.nombre_categoria}}</p>
                                    <p>37</p>
                                </a>
                            </li>
                        </ul>
                        <div class="br"></div>
                    </aside>
                </div>
            </div>
        </div>
    </div>
</section>
<!--================Blog Area =================-->

<ng-template #no_auth_modal let-modal>
    <div class="modal-header">
        <h5 class="modal-title" id="eventoModalLabel">Esta opción no está disponible</h5>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
        <span>Para poder dar like, comentar o marcar un tema como favorito y acceder a otras funcionalidades, primero debe iniciar
            sesión.</span>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="modal.close('Close click')">
            Close
        </button>
        <!--button type="button" class="btn btn-primary">Save changes</button-->
    </div>
</ng-template>

<ng-template #comment_len_modal let-modal>
    <div class="modal-header">
        <h5 class="modal-title" id="eventoModalLabel">Ups!</h5>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
        <span>Verifica tu entrada. Un comentario no puede estar vacio ni exeder los 800 caracteres.</span>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="modal.close('Close click')">
            Close
        </button>
        <!--button type="button" class="btn btn-primary">Save changes</button-->
    </div>
</ng-template>