import { Component, OnInit, Input, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Consejeria, ConsejeriaPOST, Tema } from '../agendar.citas/interfaces';
import { LoginService } from '../login/login.service';
import { HistorialCitasService } from '../historial-citas/service/historial-citas.service';
import { AgendarService } from '../agendar.citas/service/agendar.service';


@Component({
  selector: 'app-modificar-cita',
  templateUrl: './modificar-cita.component.html',
  styleUrls: ['./modificar-cita.component.css'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class ModificarCitaComponent implements OnInit {
  @Input() consejeria: Consejeria;
  constructor(private loginServices: LoginService,
    private consejeria_services: AgendarService,
    private changeDetection: ChangeDetectorRef,
    private historialService: HistorialCitasService,
  ) { }
  _undef = undefined;

  user_id = undefined;
  user = undefined;

  min_date: string = "";
  temas: Tema[] = [];
  horas: string[] = [];


  ngOnInit(): void {
    console.log(this.consejeria)
    this.user_id = this.loginServices.getUserId();
    this.user = this.loginServices.getUser();
    this.min_date = new Date().toISOString().slice(0, 10);

    this.consejeria_services.get_tema_consejeria().subscribe(data => {
      this.temas = data as Tema[];
      this.changeDetection.detectChanges();
    });
    (document.getElementById('fecha-input') as HTMLInputElement).value = this.consejeria.fecha;
    this.onChangeDate();
    //(document.getElementById('horario-select') as HTMLInputElement).value = this.consejeria.hora;
  }

  onChangeDate() {
    //(document.getElementById('horario-select') as HTMLInputElement).value = null;
    let fecha = (document.getElementById('fecha-input') as HTMLInputElement).value;
    this.consejeria_services.getHorasDisponiblesConsejeroFecha(this.consejeria.consejero.id, fecha).subscribe(data => {
      if (data['status']) {
        this.horas = data['horas'] as string[];
        (document.getElementById('horario-select') as HTMLInputElement).value = null;
        this.changeDetection.detectChanges();
      }
    })
  }

  onSubmit() {
    let tema = (document.getElementById('tema-select') as HTMLInputElement).value;
    let modalidad = (document.getElementById('modalidad-select') as HTMLInputElement).value;
    let fecha = (document.getElementById('fecha-input') as HTMLInputElement).value;
    let hora = (document.getElementById('horario-select') as HTMLInputElement).value;
    let motivo = (document.getElementById('motivo-input') as HTMLInputElement).value;

    let consejeriaPost: ConsejeriaPOST = {
      conjid: this.consejeria.id,
      tema: this.consejeria.tema.id,
      modalidad: this.consejeria.modalidad,
      link: this.consejeria.link,
      direccion: this.consejeria.direccion,
      fecha: this.consejeria.fecha,
      hora: this.consejeria.hora,
      precio: this.consejeria.precio,
      motivo: this.consejeria.motivo
    };

    if (tema != "" && tema != undefined && tema != 'null') {
      consejeriaPost.tema = parseInt(tema);
    }
    if (modalidad != null && modalidad != "" && modalidad != undefined && modalidad != 'null'){
      consejeriaPost.modalidad = modalidad;
    }
    if (fecha != null && fecha != "" && fecha != undefined){
      consejeriaPost.fecha = fecha;
    }
    if (hora != null && hora != "" && hora != undefined && hora !='null'){
      consejeriaPost.hora = hora;
    }
    if (motivo != null && motivo != undefined && motivo != ""){
      consejeriaPost.motivo = motivo;
    }

    this.historialService.editar_consejeria(consejeriaPost).subscribe(data=>{
      if(data == 'consejeria actualizada exitosamente'){
        alert('Su cita de asesoría fue modificada exitosamente.')
        window.location.reload();
      }else{
        alert('Ha ocurrido un error al intentar modificar su cita. Recargue e intentelo de nuevo.')
      }
    })

  }

  close_modal() {
    window.location.reload();
  }

}
