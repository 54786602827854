import { stringify } from '@angular/compiler/src/util';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { tr } from 'date-fns/locale';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment.prod';
import { CategoiraTemaService} from '../../components/temas-details/services/categoira-tema.service';
import { TemaService } from '../../components/temas-details/services/tema.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Comentario_Tema, Respuesta_Comentario } from './services/comentarios'
 
@Component({
  selector: 'app-temas-details',
  templateUrl: './temas-details.component.html',
  styleUrls: ['./temas-details.component.css']
})
export class TemasDetailsComponent implements OnInit {
  public mediaURL: string;

  public isLogged: Boolean = false;
  public is_liked: boolean = false;
  public like_count: number = 0;
  public is_fav: boolean = false;
  private user_id: number = null;
  private tema_id: number = null;
  public destroyed = new Subject<any>();
  navigationSubscription;

  temas_favoritos:any=[];
  categorias:any=[];
  tema_current:any=[];
  tema_by_categorys:any=[];
  images_temas:any=[];
  videos_temas:any=[];

  message:boolean=false;

  public comentarios : Comentario_Tema[] = [];
  constructor(
    private service_categorias: CategoiraTemaService,
    private service_tema:TemaService,
    private activatedRoute: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private route:Router,
    private modalService: NgbModal,
  ) {

  }
  private url  = environment.apiUrl;
  id_tema:string;

  id_category:string;
  video:any;
  nombre_categoria:string;
  ngOnInit() {
    this.mediaURL = environment.imgUrl;
    console.log('MEDIA',this.mediaURL);
    this.getLogginStatus();
    window.scrollTo(0, 0);

    this.id_tema=this.activatedRoute.snapshot.params.id;
    this.id_category=this.activatedRoute.snapshot.params.id_categoria;

    this.service_tema.post_tema_is_liked(parseInt(this.id_tema), this.user_id).subscribe((data)=>{
      if(data['status']){
        this.is_liked = data['liked'];
        this.like_count = data['like_count'];
      }else{
        this.is_liked = false;
      }
    })

    this.service_tema.post_tema_is_fav(parseInt(this.id_tema), this.user_id).subscribe((data)=>{
      if(data['status']){
        this.is_fav = data['fav'];
      }else{
        this.is_liked = false;
      }
    })

    this.service_tema.post_temas_favoritos(this.user_id).subscribe((data)=>{
      if(data['status']){
        this.temas_favoritos = data['temas'];
      }
    })

    this.service_categorias.fetch_categorias().subscribe(data=>{
      this.categorias=data;
    })

    this.service_tema.get_tema(this.id_tema).subscribe(data=>{
      this.tema_current=data;
      this.like_count=data[0]['likes'];
    })

    this.service_tema.get_imageByTema(this.id_tema).subscribe(data=>{
      this.images_temas=data;
    })

    this.service_tema.get_temasAll(this.id_category).subscribe(data=>{
      this.tema_by_categorys=data;
    });

    this.service_tema.get_videoByTema(this.id_tema).subscribe(data=>{
      this.videos_temas=data;
      this.video = this.transform('https://www.youtube.com/embed/'+this.videos_temas[0].url)
      //this.video = 'https://www.youtube.com/embed/'+this.videos_temas[0].url
    });

    this.service_tema.get_comentatios_tema(this.id_tema).subscribe((data)=>{
      //console.log('comentarios: ',data);
      this.comentarios = data['comentarios'] as Comentario_Tema[];
      console.log('comentarios',this.comentarios);
    });
  }

  onClickLink(tema: any) {
    window.location.href = `#/tema-details/${tema.id_tema}/${tema.tema_categoria_id}`
    window.location.reload()
  }

  onClickLinkFavoritos(tema: any) {
    window.location.href = `#/tema-details/${tema.id}/${tema.categoria_id}`
    window.location.reload()
  }

  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
  }

  log_ :string;
  goToConsejerias(){
    this.log_ = localStorage.getItem('isLogged');
    if(this.log_=="true"){
      this.route.navigate(['/calendar']); // navigate to other page
    }else{
      alert("No ha iniciado sesion");
      this.message=true;
    }
  }

  getLogginStatus() {
    let val = localStorage.getItem('isLogged')
    if (val == 'true') {
      this.isLogged = true;
      this.user_id = parseInt(localStorage.getItem('user_id'));
    } else {
      this.isLogged = false;
    }
  }

  onLikeClicked(){
    this.service_tema.post_tema_like(parseInt(this.id_tema), this.user_id).subscribe((data)=>{
      if(data['status']){
        this.is_liked = data['liked'];
        this.like_count = data['like_count'];
      }
    })
  }

  onFavClicked(){
    this.service_tema.post_tema_fav(parseInt(this.id_tema),this.user_id).subscribe((data)=>{
      if(data['status']){
        this.is_fav = data['fav'];
        this.loadFavs();
      }
    })

  }

  loadFavs(){
    this.service_tema.post_temas_favoritos(this.user_id).subscribe((data)=>{
      if(data['status']){
        this.temas_favoritos = data['temas'];
      }
    })
  }

  onFavLike_notLoggedIn(content){
    this.modalService.open(content, { centered: true , scrollable: true});
  }

  onComent(modal){
    this.modalService.open(modal, {centered: true, scrollable: true});
  }

  datetime_to_comment_format(fecha: string, hora: string){
    let months = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic']
    let hora_str = hora.slice(0,5);

    let month = parseInt(fecha.slice(5,7));
    let month_str = months[month-1];
    let year_str = fecha.slice(0,4);
    let day_str = fecha.slice(8,10);
    
    return `${day_str} ${month_str} ${year_str} a las ${hora_str}`
  }


  on_responder_comentario(comentario_id, no_auth_modal, comment_len_modal){
    if(!this.isLogged){
      this.modalService.open(no_auth_modal, {centered: true, scrollable: true});
      return;
    }
    let respuesta = document.getElementById(`respuesta_${comentario_id}_textArea`) as HTMLInputElement;
    if(respuesta.value == '' || respuesta.value == undefined || respuesta.value == null){
      this.modalService.open(comment_len_modal, {centered: true, scrollable: true});
    }else{
      if(respuesta.value.length > 800){
        this.modalService.open(comment_len_modal, {centered: true, scrollable: true});
      }
      //TODO: registrar respuesta
      this.service_tema.post_responder_comentario(this.user_id, parseInt(comentario_id), respuesta.value).subscribe((data)=>{
        if(data['status']){
          let res = data['respuesta_comentario'] as Respuesta_Comentario;
          for(let com of this.comentarios){
            if(com.id == res.comentario_tema_id){
              com.respuestas.push(res);
              respuesta.value = '';
            }
          } 
        }
      });
    }
  }

  on_conmentar_tema(no_auth_modal, comment_len_modal){
    if(!this.isLogged){
      this.modalService.open(no_auth_modal, {centered: true, scrollable: true});
      return;
    }
    let comentario = document.getElementById(`comentario_tema_textArea`) as HTMLInputElement;
    if(comentario.value == '' || comentario.value == undefined || comentario.value == null){
      this.modalService.open(comment_len_modal, {centered: true, scrollable: true});
    }else{
      if(comentario.value.length > 800){
        this.modalService.open(comment_len_modal, {centered: true, scrollable: true});
      }
      this.service_tema.post_comentar_tema(this.user_id, parseInt(this.id_tema), comentario.value).subscribe((data)=>{
        if(data['status']){
          let com = data['comentario'] as Comentario_Tema;
          this.comentarios.push(com)
          comentario.value = '';
        }
      })
    }
  }
}
