<section class="breadcrumb_area br_image " style="margin-top: 7rem !important;">
    <div class="container">
        <div class="page-cover text-center">
            <h2 class="page-cover-tittle">Nuestra Galería</h2>
            <ol class="breadcrumb">
                <li><a href="index.html">Inicio</a></li>
                <li class="active">Galería</li>
            </ol>
        </div>
    </div>
</section>

<div class="container">
    <div class="d-flex flex-row justify-content-center">
      <div class="d-flex flex-row justify-content-around">
        <div class="d-inline p-2 text-bg-primary">
          <button class="btn btn-primary" (click)="handleClickImagen()">Imágenes</button>
        </div>
        <div class="d-inline p-2 text-bg-dark">
          <button class="btn btn-primary" (click)="handleClickVideos()">Vídeos</button>
        </div>
      </div>
    </div>
</div>

<section  class="gallery_area section_gap">
    <div  class="container">
        <div  class="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3" id="gallery" >
            <div *ngFor="let img of contenido | paginate: { itemsPerPage: 9, currentPage: p  }" class="col-md-4 gallery_item">

                <div class="col">
                  <div class="card shadow-sm">
                    <ng-template [ngIf]="imagenBool" [ngIfElse]="elseBlock">
                      <div class="col-sm-12 px-0">
                        <img src="{{url}}/media/{{img.image}}" class="bd-placeholder-img card-img-top img-fluid" style="height: 330px!important;" alt="{{img.id_galeria_id.titulo}}">
                      </div>
                    </ng-template>
                    <ng-template #elseBlock>
                      <video src="{{url}}/media/{{img.video}}" type="video/mp4"  controls alt="{{img.id_galeria_id.titulo}}"></video>
                    </ng-template>
                    <div class="card-body">
                      <div class="mb-2" style="height:65px!important;">
                        <p class="card-text">{{img.id_galeria_id.titulo}}</p>
                      </div>
                      <div class="d-flex justify-content-between align-items-center">
                        <div class="btn-group">
                          <button type="button" class="btn btn-sm btn-primary" (click)="openVerticallyCentered(content,img)">View</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
        </div>
        <pagination-controls (pageChange)="p = $event" style="text-align: center; margin: auto;"
        maxSize="6"
        directionLinks="true"
        autoHide="true"
        responsive="true"
        previousLabel="Anterior"
        nextLabel="Siguiente"
        ></pagination-controls>

    </div>
</section>
  <ng-template #content let-modal>
    <div class="modal-header">
      <h4 class="modal-title">{{objActuallity.id_galeria_id.titulo}}</h4>
      <button
        type="button"
        class="btn-close"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"
      ></button>
    </div>
    <div class="modal-body">
      <p>{{objActuallity.id_galeria_id.descripcion}}&hellip;</p>
      <div class="col-sm-12 px-0">
        <ng-template [ngIf]="imagenBool" [ngIfElse]="elseBlock">
          <div class="col-sm-12 px-0">
            <img src="{{url}}/media/{{objActuallity.image}}" class="bd-placeholder-img card-img-top img-fluid" style="height: 330px!important;" alt="{{objActuallity.id_galeria_id.titulo}}">
          </div>
        </ng-template>
        <ng-template #elseBlock>
          <video src="{{url}}/media/{{objActuallity.video}}" class="bd-placeholder-img card-img-top img-fluid" style="height: 330px!important;" type="video/mp4"  controls alt="{{objActuallity.id_galeria_id.titulo}}"></video>
        </ng-template>
      </div>
    </div>
    <div class="modal-footer d-flex justify-content-between">
        <p class="text-start">{{objActuallity.fecha}}</p>
        <button
          type="button"
          class="btn btn-light"
          (click)="modal.close('Close click')"
        >
          Close
        </button>
      </div>
  </ng-template>

