import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Cuentas } from '../interfaces';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DonacionService {
  public apiURL = environment.apiUrl;
  public imgURL = environment.imgUrl;
  constructor(private http: HttpClient) { }

  get_cuentas(): Observable<Cuentas[]>{
    return this.http.get<Cuentas[]>(`${this.apiURL}/api/get_cuentas`)
  }
}
