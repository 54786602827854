<div class="d-flex flex-column" style="min-width: 320px; min-height: 100px;">
    <div style="position: relative">
        <div style="min-width: 320px; min-height: 100px; background-color: rgb(238, 238, 238);"></div>
        <div style="min-width: 320px; min-height: 40px; background-color: rgb(255, 255, 255);"></div>
        <span
            style="position:absolute; top:20%; text-align: center; margin: auto; min-width: 100%;">@{{usuario?.username}}</span>
        <div class="d-flex" style="position: absolute; top: 40%; min-width: 100%;">
            <img [src]="apiURL+usuario?.foto" class="profile-foto rounded-circle" style="margin: auto;">
        </div>
    </div>
    <span
        style="text-align: center; font-size: 1.3rem; margin-top: 1rem;">{{usuario?.first_name}}&nbsp;{{usuario?.last_name}}</span>
    <span style="text-align: center; font-size: 0.9rem;">{{usuario?.email}}</span>
    <div class="d-flex flex-row justify-content-center mt-3">
        <div class="d-flex border rounded-circle px-2 py-2 rounded-button" >
            <i class="bi bi-bookmark-fill" style="color: gray" data-hover="Temas guardados" routerLink="/temas-favoritos"></i>
        </div>
        <div class="d-flex border rounded-circle px-2 py-2 rounded-button" routerLink="/calendar">
            <i class="bi bi-calendar3" style="color: gray"></i>
        </div>
        <div class="d-flex border rounded-circle px-2 py-2 rounded-button" (click)="redirectToProfileInformation()">
            <i class="bi bi-person-fill" style="color: gray"></i>
        </div>
    </div>
    <div class="list-group mt-4">
        <button type="button" class="list-group-item list-group-item-action" (click)="redirectTo_SetFoto()">
            <i class="bi bi-camera-fill mr-2"></i>
            Cambiar foto de perfil
        </button>
        <button type="button" class="list-group-item list-group-item-action" (click)="redirectTo_setInfo()">
            <i class="bi bi-pen-fill mr-2"></i>
            Editar mi información
        </button>
        <button type="button" class="list-group-item list-group-item-action" (click)="redirectTo_SetPassword()">
            <i class="bi bi-lock-fill mr-2"></i>
            Cambiar contraseña
        </button>
    </div>
</div>